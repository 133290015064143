import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getRouters } from '@/api/login'
import { routerData } from '@/interface/router'
const Layout = import.meta.glob(['../layouts/Layout.vue'])
const ParentView = import.meta.glob(['../layouts/ParentView.vue'])
const InnerLink = import.meta.glob(['../layouts/InnerLink.vue'])
const modules = import.meta.glob('../views/**/*.vue')
// 巡检静态路由
const inspectionData = {
  path: '/',
  hidden: false,
  auth: false,
  type: '/inspection',
  children: [
    {
      path: 'inspection',
      hidden: false,
      meta: {
        title: '巡检系统',
        icon: 'icon-xunjian-icon',
        noCache: true,
        link: null,
        isExpand: '0',
        fastEntry: '2',
        isTab: '1',
        fastEntryImage: null,
        mobileFastEntryImage: null,
        activeMenu: '',
        iconPath: null,
        selectedIconPath: null,
        isBuriedPoint: '1',
        mobileMenuName: null,
        orderNum: '15',
        orderMobileTab: '1',
        orderWebFastEntry: '1',
        orderMobileFastEntry: '11',
        concatPath: '/inspection',
        analyticsIgnore: true
      }
    }
  ],
  orderNum: '15'
}
// 入店率静态路由
const passengerData = {
  path: '/',
  hidden: false,
  auth: false,
  type: '/passenger',
  children: [
    {
      path: 'passenger',
      hidden: false,
      meta: {
        title: '入店率分析',
        icon: 'icon-keliu',
        noCache: true,
        link: null,
        isExpand: '0',
        fastEntry: '2',
        isTab: '1',
        fastEntryImage: null,
        mobileFastEntryImage: 'mobile-fast-passenger',
        activeMenu: '',
        iconPath: null,
        selectedIconPath: null,
        isBuriedPoint: null,
        mobileMenuName: null,
        orderNum: '12',
        orderMobileTab: '1',
        orderWebFastEntry: '1',
        orderMobileFastEntry: '10',
        concatPath: '/passenger',
        analyticsIgnore: true
      }
    }
  ],
  orderNum: '12'
}

export const permissionStore = defineStore('permission', () => {
  const originRoutes = ref([]) //[都有效的排序]排序后的源数据的数组
  const routes = ref<Array<routerData>>([]) //排序后[可能无效的排序]，处理过组件路径/嵌套逻辑等数据的数组
  const expandRoutes = ref<Array<string>>([]) //默认需要展开的数据
  const cachedViews = ref<any>([]) //默认需要缓存的数据
  const dynamicRoutes = ref([]) //暂未使用
  const modulesFiles = ref() //所有的模块文件和路径
  const oneDimentionRoutes = ref<any>([]) //一维数组的所有的模块文件和路径

  const fastEntryRoutes = ref<Array<routerData>>([]) //快捷入口的数组
  const tabRoutes = ref<Array<routerData>>([]) //暂未使用
  const mobileFastEntryRoutes = ref<Array<routerData>>([]) //移动端快捷入口的数组

  // 原数据
  const defaultRoutes = ref([]) // 暂未使用，以前的数据源字段
  const topbarRouters = ref([]) // 暂未使用，以前的数据源字段
  const sidebarRouters = ref([]) // 暂未使用，以前的数据源字段

  /**
   * @function splitPagesName 根据传入的data将views的前缀和.vue文件后缀去除，保存为新的数据，
   * @param {any} data import.meta.glob引入的所有view页面
   *
   * */
  const splitPagesName = (data: any) => {
    const obj: { [x: string]: any } = {}
    for (const path in data) {
      const dir = path.split('views/')[1].split('.vue')[0]
      obj[dir] = data[path]
    }
    return obj
  }

  /**
   * @function generateRoutes
   * @returns {Promise<Array<routerData> | void>} 返回的vue-router需要的路由数据
   *
   **/
  const generateRoutes = async (): Promise<Array<routerData> | []> => {
    try {
      let res = await getRouters()
      if (res?.code === 200) {
        originRoutes.value = res?.data.sort((prev: { meta: any; children: { meta: any }[] }, next: { meta: any; children: { meta: any }[] }) => {
          let prevMeta = prev.meta || prev.children[0].meta
          let nextMeta = next.meta || next.children[0].meta
          return prevMeta?.orderNum - nextMeta?.orderNum || 0
        })
        modulesFiles.value = splitPagesName(modules)
        const data: Array<routerData> = getOrderCompRoutes(res?.data)

        // let pathData = oneDimentionRoutes.value?.filter((el: { name: string }) => !el.name)?.map((el: { path: string }) => el.path)
        // console.log(oneDimentionRoutes.value, 'oneDimentionRoutes.value', data)
        // if (!pathData.includes('passenger')) {
        //   data.push(passengerData as any)
        //   oneDimentionRoutes.value.push(passengerData)
        // }
        // if (!pathData.includes('inspection')) {
        //   data.push(inspectionData as any)
        //   oneDimentionRoutes.value.push(inspectionData)
        // }
        routes.value = data
        return { result: data, origin: oneDimentionRoutes.value } as any
      } else {
        return []
      }
    } catch (error) {
      console.log(error)
      return []
    }
  }

  /**
   * @function getOrderCompRoutes 根据getRoutes接口返回的所有数据去
   * @param {Array<routerData>} data 后端返回的数据处理后的路由数组
   * @param {string} parent 父级path+'/'拼接成完整的路由
   * */
  const getOrderCompRoutes = (data: Array<routerData>, parent: string = ''): Array<routerData> => {
    return data
      .sort((a: any, b: any): any => {
        if (b.orderNum && a.orderNum) {
          return a.orderNum - b.orderNum
        } else {
          return
        }
      })
      .filter((el: routerData | any) => {
        let parentStr: string = parent
        if (parent != '/' && el.path && el.path != '/') {
          if (el.path.substr(0, 1) === '/') {
            parentStr = parent + '' + el.path
          } else {
            parentStr = [parent, el.path].join('/')
          }
        }
        if (parentStr) el.meta.concatPath = parentStr
        if (el.component) {
          // Layout ParentView InnerLink 组件特殊处理
          if (el.component.toLowerCase() === 'layout' || el.component === 'Navigate') {
            el.component = () => Layout['../layouts/Layout.vue']()
          } else if (el.component === 'ParentView') {
            el.component = () => ParentView['../layouts/ParentView.vue']()
          } else if (el.component === 'InnerLink') {
            el.component = () => InnerLink['../layouts/InnerLink.vue']()
          } else {
            el.component = modulesFiles.value[el.component]
          }
        }

        if (el.meta) {
          el.meta.analyticsIgnore = true
        }
        if (el.meta && el.meta.isExpand && el.meta.isExpand === '0') {
          expandRoutes.value.push(parentStr)
        }

        if (el.meta && el.meta.fastEntry && (el.meta.fastEntry.includes('1') || el.meta.fastEntry === '1,2')) {
          fastEntryRoutes.value.push({ path: parentStr, meta: el.meta })
        }
        if (el.meta && el.meta.fastEntry && (el.meta.fastEntry.includes('2') || el.meta.fastEntry === '1,2')) {
          mobileFastEntryRoutes.value.push({ path: parentStr, meta: el.meta })
        }

        if (el.meta && el.meta.isTab && el.meta.isTab === '0') {
          tabRoutes.value.push({ path: parentStr, meta: el.meta })
        }

        if (el.meta && !el.meta.noCache) {
          cachedViews.value.push(el.name)
        }
        if (el.children != null && el.children && el.children.length > 0) {
          el.children = getOrderCompRoutes(el.children, parentStr)
        }

        oneDimentionRoutes.value.push(el)
        return true
      })
  }

  /**
   * @function getCurrentRoutes 根据传入的路由name或path路径的'/'的前缀查找当前路由及其子项
   * @param {string} str 当前页面的name字段
   * @returns {routerData} 需要匹配查找的路由
   **/
  const getCurrentRoutes = (str: string): routerData => {
    let results: any = {
      name: 'index',
      path: '/index'
    }
    const _run = (data: Array<routerData>, str: string) => {
      for (let i = 0; i < data.length; i++) {
        const el = data[i]
        if (el?.name?.toLowerCase() === str.toLowerCase()) {
          results = el
        } else {
          if (el.children && el.children.length) {
            _run(el.children, str)
          }
        }
      }
    }
    _run(routes.value, str)
    return results
  }
  /**
   * @function clearAll 清空该store下的所有数据
   **/

  const clearAll = () => {
    originRoutes.value = []
    routes.value = []
    expandRoutes.value = []
    cachedViews.value = []
    dynamicRoutes.value = []
    modulesFiles.value = []

    originRoutes.value = []
    routes.value = []
    expandRoutes.value = []
    cachedViews.value = []
    dynamicRoutes.value = []
    modulesFiles.value = []

    fastEntryRoutes.value = []
    tabRoutes.value = []
    mobileFastEntryRoutes.value = []

    defaultRoutes.value = []
    topbarRouters.value = []
    sidebarRouters.value = []
    oneDimentionRoutes.value = []
  }

  return {
    routes,
    expandRoutes,
    fastEntryRoutes,
    tabRoutes,
    mobileFastEntryRoutes,
    dynamicRoutes,
    defaultRoutes,
    topbarRouters,
    sidebarRouters,
    oneDimentionRoutes,
    generateRoutes,
    getCurrentRoutes,
    clearAll
  }
})
