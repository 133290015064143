<template>
  <div class="w-full describe">
    <div
      class="describe-title"
      v-if="!props.hiddenTitle">
      <div
        v-if="slots.includes('title')"
        class="title-box">
        <span class="title-icon"></span>
        <slot name="title"></slot>
      </div>

      <div v-else>
        <div class="title-box">
          <span class="title-icon"></span>
          <span class="title-font">{{ props.title }}</span>
        </div>
      </div>
      <slot name="titleOperate"></slot>
    </div>
    <div :class="[displayType == 'grid' ? 'describe-body-grid' : 'describe-body-flex', !props.labelAutoWidth ? 'describe-body' : 'describe-body-auto']">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, useSlots, PropType } from 'vue'
type displayTypes = 'grid' | 'flex'
/**
 * @param {string} title 描述列表标题
 * @param {displayTypes} displayType 布局类型 默认 grid
 * @param {number} cols 当displayType为grid生效 列
 * @param {number}  rows 当displayType为grid生效 行
 * @param {boolean} hiddenTitle 是否隐藏标题
 * @param {boolean} labelAutoWidth label自动宽度 -获取最大宽度 统一宽度
 */
const props = defineProps({
  title: {
    type: String,
    default: '请输入标题'
  },
  displayType: {
    type: String as PropType<displayTypes>,
    default: 'grid'
  },
  cols: {
    type: Number,
    default: 2
  },
  rows: {
    type: Number || String,
    default: 'auto'
  },
  hiddenTitle: {
    type: Boolean,
    default: false
  },
  labelAutoWidth: {
    type: Boolean,
    default: true
  },
  isMb: {
    type: Boolean,
    default: true
  }
})
const slots = Object.keys(useSlots())

const init = () => {
  // 获取当前最大的 label宽度 统一所有宽度一致
  if (props.labelAutoWidth) {
    nextTick(() => {
      let domArr = document.querySelectorAll('.describe-body-auto  .describe-item-label') as any
      console.log(domArr, 'domArr')
      let widthArr = []
      for (let i = 0; i < domArr.length; i++) {
        widthArr.push(domArr[i].clientWidth)
      }
      console.log(Math.max(...widthArr))
      for (let i = 0; i < domArr.length; i++) {
        domArr[i].style.minWidth = Math.max(...widthArr) + 'px'
      }
    })
  }
}
init()
</script>
<style lang="less" scoped>
.describe {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: var(--times-10);
  }
  .describe-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: var(--times-6);
    margin-bottom: var(--times-6);
    .title-box {
      display: inline-flex;
      align-items: center;
      .title-icon {
        border-width: 2px;
        border-style: solid;
        border-color: rgb(var(--primary-6));
        background-color: rgb(var(--primary-6));
        margin-right: var(--times-4);
        height: var(--times-8);
        display: inline-block;
        box-sizing: border-box;
      }
      .title-font {
        font-size: var(--times-8);
        font-weight: 600;
      }
    }
  }
  .describe-body.describe-body-grid,
  .describe-body-auto.describe-body-grid {
    display: grid;
    grid-template-columns: repeat(v-bind('props.cols'), minmax(0, 1fr));
    grid-template-rows: repeat(v-bind('props.rows'), minmax(0, 1fr));
    gap: var(--times-6);
  }
  .describe-body.describe-body-flex,
  .describe-body-auto.describe-body-flex {
    display: flex;
    gap: var(--times-6);
    flex-wrap: wrap;
  }
}
</style>
