<template>
  <div class="radio-group">
    <label
      :class="value == item.value ? 'active' : ''"
      :key="index"
      v-for="(item, index) in props.options"
      class="radio-group-item">
      {{ item.label }}
      <input
        class="radio-group-input"
        type="radio"
        :value="item.value"
        v-model="value"
        @change="handleRadioChange(item.value)" />
    </label>
  </div>
</template>

<script lang="ts" setup>
import { ref, PropType, computed, watch, onBeforeUnmount } from 'vue'

const props = defineProps({
  options: {
    type: Object as PropType<{ label: string; value: string | number; color?: string }[]>
  },
  defaultValue: {
    type: [String, Number]
  },
  value: {
    type: [String, Number]
  }
})

const emits = defineEmits(['update:value'])

const value = ref()

const currentColor = computed(() => {
  if (props.options) {
    const index = props.options.findIndex((el) => el.value == value.value)
    if (index != -1) {
      return props.options[index]?.color || 'var(--primary-normal)'
    }
  }
  return 'var(--primary-normal)'
})

console.log(currentColor)

const handleRadioChange = (item: any) => {
  value.value = item
  emits('update:value', item)
  emits('update:value', item)
}
value.value = props.value || props.defaultValue || void 0
const watchValue = watch(
  () => props.value,
  () => {
    value.value = props.value
  }
)
onBeforeUnmount(() => {
  watchValue && watchValue()
})
</script>

<style lang="less" scoped>
.radio-group {
  // display: flex;
  gap: 12px;
  font-size: 12px;
  .radio-group-item.active::after,
  .radio-group-item.active::before {
    display: block;
  }
  .radio-group-item.active {
    border: 1px solid v-bind('currentColor');
    color: v-bind('currentColor');
  }
  &-item {
    border: 1px solid var(--border-light);
    border-radius: 6px;
    padding: 8px 0;
    position: relative;
    overflow: hidden;
    background: #fff;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &-item:not(:last-child) {
    margin-right: 8px;
  }
  .radio-group-item::after {
    content: '✔';
    position: absolute;
    bottom: -1px;
    right: 0;
    z-index: 11;
    color: #fff;
    font-size: 10px;
    display: none;
  }
  .radio-group-item::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border: 8px solid v-bind('currentColor');
    border-top-color: transparent;
    border-left-color: transparent;
    z-index: 10;
    display: none;
  }
  &-input {
    display: none;
  }
}
</style>
