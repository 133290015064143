<template>
  <div>
    <template v-for="(item, index) in options">
      <template v-if="values.includes(item.value)">
        <span
          v-if="item.elTagType == 'default' || item.elTagType == ''"
          :key="item.value"
          :index="index"
          :class="item.elTagClass">
          {{ item.label }}
        </span>
        <a-tag
          v-else
          :key="item.value + ''"
          :index="index"
          :type="item.elTagType"
          :color="item.elTagType === 'primary' ? 'arcoblue' : item.elTagType === 'danger' ? 'red' : 'primary'"
          :class="item.elTagClass">
          {{ item.label }}
        </a-tag>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface ITags {
  elTagType: string
  value: string
  elTagClass: string
  label: string
}

const props = defineProps({
  // 数据
  options: {
    type: Array<ITags>,
    default: null
  },
  // 当前的值
  value: [Number, String, Array]
})

const values = computed(() => {
  if (props.value !== null && typeof props.value !== 'undefined') {
    return Array.isArray(props.value) ? props.value : [String(props.value)]
  } else {
    return []
  }
})
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
</style>
