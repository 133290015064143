import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getInfo, logout } from '@/api/login'
import { removeToken } from '@/utils/auth'
// import defAva from '@/assets/icons/avatar.svg'
import { ResponseData } from '@/interface/userInfo'
import { Message } from '@arco-design/web-vue'
import { saveSkipRecord } from '@/api'
import { getUserDeviceType } from '@/utils/public'

export const userInfoStore = defineStore('userInfo', () => {
  const userInfo = ref()
  const permissions = ref()
  const roles = ref()
  const isEditUser = ref()
  const jobIdArr = ref<Array<{ userId: string | undefined; jobId: string }>>([])
  const empowerJobArr = ref<{ [x: string]: string | boolean }[]>([])
  const noReadMsgNum = ref(0)
  const getUserInfo = (): Promise<ResponseData | void> => {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((res: ResponseData | void) => {
          if (res?.code === 200) {
            userInfo.value = null
            userInfo.value = res.user
            roles.value = res.roles
            permissions.value = res.permissions
            isEditUser.value = res.isEditUser
            if (res.user?.avatar.length) {
              userInfo.value.avatar = window.VITE_APP_BASE_API ? window.VITE_APP_BASE_API + res.user?.avatar : import.meta.env.VITE_APP_BASE_API + res.user?.avatar
            }
            resolve(res)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  const loginOut = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          isEditUser.value = ''
          userInfo.value = ''
          roles.value = []
          permissions.value = []
          jobIdArr.value = []
          empowerJobArr.value = []
          removeToken()
          resolve(true)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
  const handleJudgeAuth = (arr: Array<string>): boolean => {
    let flag = false
    const all_permission = '*:*:*'
    if (arr && arr instanceof Array && arr.length > 0) {
      const permissionFlag = arr
      const hasPermissions = permissions.value.some((permission: string) => {
        return all_permission === permission || permissionFlag.includes(permission)
      })
      flag = hasPermissions
    } else {
      throw new Error(`请设置操作权限标签值`)
    }
    return flag
  }

  // 上传用户跳转信息
  const uploadUserSkipInfo = async (system: number) => {
    try {
      const res = await saveSkipRecord({
        resource: getUserDeviceType() == 'mobile' ? 2 : 1,
        target: system
      })
    } catch (err) {
      console.log(err)
    }
  }

  return {
    userInfo,
    permissions,
    roles,
    isEditUser,
    jobIdArr,
    empowerJobArr,
    getUserInfo,
    loginOut,
    handleJudgeAuth,
    uploadUserSkipInfo,
    noReadMsgNum
  }
})
