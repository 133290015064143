<template>
  <div class="w-full h-full flex">
    <div
      style="word-break: break-all"
      :id="props.id"
      :class="{ clamp: lineCount > 1 && !open }">
      {{ props.text }}
    </div>
    <div
      v-if="lineCount > 1"
      class="whitespace-nowrap text-blue-700 cursor-pointer text-times-7 select-none"
      @click="open = !open">
      {{ open ? '收起' : '展开' }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, ref, watch } from 'vue'

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: 'id1'
  }
})
const open = ref<boolean>(false)
const lineCount = ref<number>(0)
const isTextOverflow = () => {
  nextTick(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const container = document.querySelector(`#${props.id}`) as any
    const lineHeight = parseInt(getComputedStyle(container).lineHeight)
    const containerHeight = container.clientHeight
    lineCount.value = Math.floor(containerHeight / lineHeight)
  })
}

watch(
  () => props.text,
  () => {
    if (props.text) {
      console.log(props.text)
      isTextOverflow()
    }
  },
  {
    immediate: true,
    deep: true
  }
)
</script>
<style lang="less">
.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 设置最大显示行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
