import instance from '@/plugins/request'
import { ResponseData } from '@/interface/request'

export const getRiskLists = (data: unknown): Promise<ResponseData | void> => {
  return instance.request({
    method: 'post',
    url: `web/riskAlarm/getRiskAlarmList`,
    data
  })
}
export const getWorkLists = (): Promise<ResponseData | void> => {
  return instance.request({
    method: 'get',
    url: `/web/equipment/workingTableList`
  })
}
export const getWorkTableLists = (): Promise<ResponseData | void> => {
  return instance.request({
    method: 'get',
    url: `/web/monitoring/workingTableList`
  })
}

// 上传文件
export const uploadImage = (data: unknown, timeout?: number): Promise<ResponseData | void> => {
  return instance.request({
    method: 'post',
    url: `/common/upload`,
    data,
    timeout,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 获取终端地址
export const getTerminalUrl = (): Promise<ResponseData | void> => {
  return instance.request({
    method: 'get',
    url: '/getJumpUrl'
  })
}
// 获取终端地址
export const getSystemDetails = (): Promise<ResponseData | void> => {
  return instance.request({
    method: 'get',
    url: '/getSystemDetails',
    deleteAuthorization: true
  })
}

//保存用户跳转记录
export const saveSkipRecord = (data: unknown): Promise<ResponseData | void> => {
  return instance.request({
    method: 'post',
    url: `/system/sso/saveSsoLog`,
    data
  })
}
