import { ref } from 'vue'
import { defineStore } from 'pinia'
import { setSession, getSession, removeSession } from '@/utils/auth'
import { clearAlL as clearStoreAlL, removeItemFromPath } from '@/store/plugins/storage'
import { LocationQuery } from 'vue-router'

export type routerListTypes = { name: string; path: string; query: LocationQuery }

let routerList = import.meta.env.VITE_APP_PREFIX + 'routerList'
export default defineStore('topTags', () => {
  const staticList = [{ path: '/index', name: '工作台', query: {} }]
  const topTagsList = ref<routerListTypes[]>([...staticList])

  /**
   * @function getList 获取存储在storage中的数据
   * */
  const getList = () => {
    topTagsList.value = JSON.parse(getSession(routerList)) || [...staticList]
    return topTagsList.value
  }
  /**
   * @function addList 新增topTags
   * @param {routerListTypes} page 传入的path
   **/
  const addList = (page: routerListTypes) => {
    if (page.path == '/index') return
    let isContain = false
    if (!topTagsList.value?.length) topTagsList.value = [...staticList]
    if (topTagsList.value?.length) {
      for (let i = 0; i < topTagsList.value.length; i++) {
        const el = topTagsList.value[i]
        if (el.path == page.path) {
          topTagsList.value[i].query = page.query
          isContain = true
        }
      }
    }
    if (!isContain) {
      topTagsList.value.push(page)
    }
    setSession(routerList, JSON.stringify(topTagsList.value))
  }
  /**
   * @function delList 删除topTags的某一项,
   * @param {string} path 传入的path
   **/
  const delList = (path: string) => {
    if (path == '/index') return
    let prevEl = 0
    const results = topTagsList.value?.filter((el: routerListTypes, index: number) => {
      prevEl = index - 1
      return el.path != path
    })
    const search = window.location.hash || window.location.search
    if (search.includes(path)) {
      let strArr = []
      for (const key in results[prevEl].query) {
        const el = results[prevEl].query[key]
        if (el != undefined) {
          strArr.push(`${key}=${el}`)
        }
      }
      if (strArr?.length > 0) {
        window.location.replace(window.location.origin + window.location.pathname + '#' + results[prevEl].path + '?' + strArr.join('&'))
      } else {
        window.location.replace(window.location.origin + window.location.pathname + '#' + results[prevEl].path)
      }
    }
    topTagsList.value = results
    setSession(routerList, JSON.stringify(topTagsList.value))
    // 删除store缓存
    removeItemFromPath(path)
  }

  const clearAll = () => {
    removeSession(routerList)
    topTagsList.value = [...staticList]
    // 清除store的缓存
    clearStoreAlL()
  }

  return {
    topTagsList,
    clearAll,
    getList,
    addList,
    delList
  }
})

export const routerListName = routerList
