<template>
  <middle-modal
    size="xl"
    v-model:visible="pictureEditvisible"
    title=" "
    :on-before-ok="handleOk"
    :unmountOnClose="true"
    :modal-class="'picture-editmodal'"
    @close="closeModal">
    <div class="canvas-container h-[500px] relative">
      <canvas
        class="canvaselement box-border w-full"
        @mousedown="canvasMouseDown"
        @mousemove="canvasMouseMove"
        @mouseup="canvasMouseUp"></canvas>
      <div
        v-show="isDrawWord"
        class="absolute top-0 left-0 right-0 bottom-0 draw-word-container">
        <div
          :style="{
            left: drawWordBoxPosition.x + 'px',
            top: drawWordBoxPosition.y + 'px'
          }"
          class="border absolute border-solid p-sm flex items-center w-[240px] cursor-move select-none"
          @mousemove="onMouseMove"
          @mousedown="onMouseDown"
          @mouseup="onMouseUp">
          <input
            v-model="canvasWord"
            class="" />
          <div class="mx-xs cursor-pointer insert-word-ok">确定</div>
          <div class="cursor-pointer insert-word-close">取消</div>
        </div>
      </div>
    </div>
    <div class="flex pt-xs">
      <!-- <div
        @click="isPaintbrush = !isPaintbrush"
        :class="isPaintbrush ? 'border-primary-normal text-primary-normal' : 'border-bg-hover '"
        class="w-[30px] h-[30px] mr-xs border border-solid cursor-pointer rounded-xs text-center leading-[28px]">
        <iconfont
          name="icon-icon_edit-1"
          class=""></iconfont>
      </div> -->
      <a-popover trigger="click">
        <div class="w-[40px] h-[30px] mr-xs border border-solid border-bg-hover cursor-pointer rounded-xs text-center leading-[28px] text-times-6 select-none">
          {{ thickness }}px
        </div>
        <template #content>
          <a-slider
            v-model:model-value="thickness"
            :style="{ width: '200px' }" />
        </template>
      </a-popover>
      <div class="w-[60px] h-[30px] mr-xs border border-solid border-bg-hover cursor-pointer rounded-xs flex items-center justify-center">
        <color-picker
          v-model:pureColor="paintbrushColor"
          v-model:gradientColor="gradientColor" />
      </div>
      <div
        @click="canvasUndo"
        class="w-[30px] h-[30px] mr-xs border border-solid border-bg-hover cursor-pointer rounded-xs text-center leading-[28px]">
        <iconfont name="icon-chexiao"></iconfont>
      </div>
      <div
        @click="canvasRedo"
        class="w-[30px] h-[30px] mr-xs border border-solid border-bg-hover cursor-pointer rounded-xs text-center leading-[28px]">
        <iconfont name="icon-zhongzuo"></iconfont>
      </div>
      <div
        @click="clearCanvas"
        class="w-[30px] h-[30px] mr-xs border border-solid border-bg-hover cursor-pointer rounded-xs text-center leading-[28px]">
        <iconfont name="icon-xiangpica"></iconfont>
      </div>
      <!-- <div
        @click="changeDrawWord"
        :class="isDrawWord ? 'border-primary-normal text-primary-normal' : 'border-bg-hover '"
        class="w-[30px] h-[30px] mr-xs border border-solid cursor-pointer rounded-xs text-center leading-[28px]">
        <iconfont
          name="icon-icon_edit-1"
          class=""></iconfont>
      </div> -->
    </div>
  </middle-modal>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, ref, render } from 'vue'
import { ColorPicker } from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'
const props = defineProps({
  base64: {
    type: String,
    default: ''
  }
})
const emits = defineEmits(['handleOutPut'])
const pictureEditvisible = ref(false)
let canvas: any
let ctx: any
const isMouseDown = ref<boolean>(false)
const isPaintbrush = ref(true)
const thickness = ref(5)
const paintbrushColor = ref('')
const gradientColor = ref('')
const isDrawWord = ref(false)
let canvasHistory: any = []
let step = -1
const canvasWord = ref<string>('')
const drawWordBoxPosition = ref<any>({
  x: 500,
  y: 300
})
const isMouseDownByWord = ref<boolean>(false)
const canvasMouseDown = (e: any) => {
  if (!isPaintbrush.value) {
    return
  }
  if (ctx && canvas) {
    //记录画笔
    step++
    if (step < canvasHistory.length) {
      canvasHistory.length = step // 截断数组
    }
    //端点平滑
    ctx.lineCap = 'round'
    ctx.lineJoin = 'round'
    //画笔粗细
    ctx.lineWidth = thickness.value
    //画笔颜色
    ctx.strokeStyle = paintbrushColor.value
    isMouseDown.value = true
    ctx.beginPath()
    ctx.moveTo(e.offsetX, e.offsetY)
  }
}
const canvasMouseMove = (e: any) => {
  if (!isPaintbrush.value) {
    return
  }
  if (ctx && canvas && isMouseDown.value) {
    ctx.lineTo(e.offsetX, e.offsetY)
    ctx.stroke()
  }
}
const canvasMouseUp = () => {
  if (!isPaintbrush.value) {
    return
  }
  if (ctx && canvas) {
    isMouseDown.value = false
    canvas.onmousemove = null
    canvasHistory.push(canvas.toDataURL())
  }
}
const clearCanvas = () => {
  if (step == 0 || canvasHistory.lenght < 2) {
    return
  }
  if (ctx && canvas) {
    // ctx.clearRect(0, 0, canvas.width, canvas.height)
    step = 0
    // canvasHistory = canvasHistory[0]
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    let canvasPic = new Image()
    canvasPic.src = canvasHistory[step]
    canvasPic.addEventListener('load', () => {
      ctx.drawImage(canvasPic, 0, 0)
    })
    step = 0
    canvasHistory = [canvasHistory[0]]

    console.log(step, canvasHistory.length, 'fadsf')
  }
}
//上一
const canvasUndo = () => {
  if (step > 0) {
    step--
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    let canvasPic = new Image()
    canvasPic.src = canvasHistory[step]
    canvasPic.addEventListener('load', () => {
      ctx.drawImage(canvasPic, 0, 0)
    })
  } else {
    console.log('不能再继续撤销了')
  }
  console.log(step, canvasHistory.length, 'fadsf')
}
// 下一
const canvasRedo = () => {
  if (step < canvasHistory.length - 1) {
    step++
    let canvasPic = new Image()
    canvasPic.src = canvasHistory[step]
    canvasPic.addEventListener('load', () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(canvasPic, 0, 0)
    })
  } else {
    console.log('已经是最新的记录了')
  }
  console.log(step, canvasHistory.length, 'fadsf')
}

const handleOk = (done: (closed: boolean) => void | boolean) => {
  emits('handleOutPut', canvas.toDataURL('image/jpeg', 1))
  done(true)
}
const closeModal = () => {
  pictureEditvisible.value = false
}
const changeDrawWord = () => {
  isDrawWord.value = !isDrawWord.value
  if (isDrawWord.value) {
    // nextTick(() => {
    //   let box: any = document.querySelector('.draw-word-container')
    //   let input = `<div class="border border-solid p-sm flex items-center w-[240px]">
    //   <textarea  />
    //   <div
    //     class="mx-xs cursor-pointer insert-word-ok"
    //   >
    //     确定
    //   </div>
    //   <div
    //     class="cursor-pointer insert-word-close"
    //     >
    //     取消
    //   </div>
    // </div>`
    //   box.innerHTML = input
    //   let ok: any = document.querySelector('.insert-word-ok')
    //   let close: any = document.querySelector('.insert-word-close')
    //   if (ok) {
    //     ok.onclick = () => {
    //       console.log('dasokj')
    //     }
    //   }
    //   if (close) {
    //     close.onclick = () => {
    //       console.log('close')
    //     }
    //   }
    // })
  }
}
const onMouseMove = (e: MouseEvent) => {
  //offset 对内
  console.log(e) //y478  x543
  if (isMouseDownByWord.value) {
    drawWordBoxPosition.value = {
      x: e.screenX - 100,
      y: e.screenY - 178
    }
  }
}
const onMouseDown = (e: MouseEvent) => {
  console.log(e)
  isMouseDownByWord.value = true
}
const onMouseUp = (e: MouseEvent) => {
  console.log(e)
  isMouseDownByWord.value = false
}
const init = () => {
  nextTick(() => {
    try {
      canvas = document.querySelector('.canvaselement')
      canvas.width = document.querySelector('.canvas-container')?.clientWidth
      canvas.height = document.querySelector('.canvas-container')?.clientHeight
      canvas.style.cursor = `pointer`
      ctx = canvas.getContext('2d')
      if (ctx) {
        if (props && props.base64) {
          const img = new Image()
          img.src = props.base64
          img.crossOrigin = 'anonymous'
          img.onload = () => {
            var autoImg = document.createElement('canvas')
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
            var pattern = ctx.createPattern(autoImg, 'no-repeat')
            ctx.fillStyle = pattern
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            step++
            if (step < canvasHistory.length) {
              canvasHistory.length = step // 截断数组
            }
            canvasHistory.push(canvas.toDataURL())
            console.log(step, canvasHistory.length, 'fadsf')
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  })
}
init()
</script>

<style lang="less">
.picture-editmodal {
  // .canvaselement {
  //   cursor: url('../../assets/biii.cur'), auto !important;
  // }
  .vc-color-wrap {
    margin-right: 0 !important;
  }
}
</style>
