<template>
  <svg
    class="svg-icon"
    aria-hidden="true">
    <use :href="iconName" />
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
const props = defineProps({
  prefix: {
    type: String,
    default: 'icon'
  },
  name: {
    type: String,
    default: ''
  }
})

let iconName = computed(() => {
  return ('#' + props.prefix + '-' + props.name)?.replaceAll(' ', '')
})
</script>
<style lang="less" scoped>
.sub-el-icon,
.nav-icon {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  font-size: 15px;
}

.svg-icon {
  position: relative;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  vertical-align: -2px;
}
</style>
