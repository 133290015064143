import { ref } from 'vue'
import { defineStore } from 'pinia'
import config from '@/static/color.json'
import { RouteLocationNormalized } from 'vue-router'
import { getSession, getStorage, removeSession, setSession } from '@/utils/auth'
import { routerListName } from '@/store/component/top-tags'
import spaceConfig from '@/static/space.json'

export default defineStore('register', () => {
  const isShowCacheTags = true
  const isShowSplitMenu = false
  const isShowSplitMenuChildren = true
  const isUseSocket = true
  const isHideSplitMenuIcon = true
  const hideIconName = 'icon_space' // splitMenu的菜单需要隐藏图标时，可以选择名称叫这个的菜单图标
  const times = ref(spaceConfig.time) //当前像素点的倍数
  const maxPxLimit = ref(spaceConfig.maxLimit) //最大倍数范围

  const theme = ref<'light' | 'dark' | undefined>('light')
  const isShowSidebar = ref<boolean>(true)
  const isShowTopTag = ref<boolean>(false)
  const tableBorder = ref<boolean>(false)
  const tableStripe = ref<boolean>(false)
  const tableHover = ref<boolean>(true)
  const scroll = ref({ minWidth: 800, x: '100%', y: 'calc(100vh - 173px)' })
  const dateFormat = ref('YYYY/MM/DD HH:mm:ss')

  /**
   * @function errorListener
   * 错误监听函数
   * */
  const errorListener = (event: ErrorEvent) => {
    let target: any = event.target
    let isElementTarget = target instanceof HTMLScriptElement || target instanceof HTMLLinkElement || target instanceof HTMLImageElement
    if (!isElementTarget) return false
    let url = target.src || target.href
    console.log(url + '资源加载错误')
  }
  /**
   * @function registerColor
   * 将color.json中的颜色全部生成css变量注入到html中
   * */
  const registerColor = () => {
    type colorTypes = { [x: string]: string | colorTypes }
    const colorMap: colorTypes = {}
    const _run = (colorObj: colorTypes, parentStr: string) => {
      for (const key in colorObj) {
        const parent = parentStr + '-' + key
        if (typeof colorObj[key] === 'string') {
          colorMap[parent] = colorObj[key]
        } else {
          _run(colorObj[key] as colorTypes, parent)
        }
      }
    }
    _run(config, '-')
    for (const key in colorMap) {
      document.documentElement.style.setProperty(key, colorMap[key] as string)
    }
  }

  /**
   * @function registerSpace
   * 将totalTimes的2倍的像素根据倍数生成像素
   * */
  const registerSpace = () => {
    Array.from({ length: maxPxLimit.value }).forEach((el, index) => {
      document.documentElement.style.setProperty('--times-' + (index + 1), (index + 1) * times.value + 'px')
    })
  }

  /**
   * @function registerTheme 注册主题是亮色还是暗色
   * */
  const registerTheme = () => {
    if (theme.value != 'light') {
      document.body.setAttribute('arco-theme', 'dark')
    } else {
      document.body.removeAttribute('arco-theme')
    }
  }
  /**
   * @function getConfigInfo 获取配置项信息，把配置项信息放入window.globalConfig中
   * */
  const getConfigInfo = async () => {}

  return {
    errorListener,
    registerColor,
    registerTheme,
    registerSpace,
    getConfigInfo,
    isShowCacheTags,
    isShowSplitMenu,
    isShowSplitMenuChildren,
    isUseSocket,
    theme,
    isShowSidebar,
    isShowTopTag,
    tableBorder,
    tableStripe,
    tableHover,
    dateFormat,
    scroll,
    hideIconName,
    isHideSplitMenuIcon
  }
})
