<template>
  <a-modal
    :modal-class="props.isBorder ? 'define-modal define-modal-border' : 'define-modal'"
    :width="width"
    v-bind="finalValue"
    :modal-style="{ '--padding': props.padding }"
    title-align="start"
    :mask-closable="false">
    <template
      v-for="(_item, key, index) in slots"
      :key="index"
      v-slot:[key]="slotScope">
      <slot
        :name="key"
        v-bind="slotScope"></slot>
    </template>
  </a-modal>
</template>

<script lang="ts" setup>
import { ref, useSlots, onBeforeUnmount, PropType, watch, useAttrs, computed } from 'vue'

const attrs = useAttrs()
const slots = useSlots()

const defaultSize = {
  sm: 420,
  md: 600,
  lg: 940,
  xl: 1200,
  fullscreen: '100%'
}
const finalValue = computed(() => {
  const { size, isBorder, padding, width, ...restAttrs } = attrs
  return restAttrs
})
const props: { size: keyof typeof defaultSize; isBorder: boolean; padding: string | number } = defineProps({
  size: {
    //弹出框尺寸
    type: String as PropType<keyof typeof defaultSize>,
    default: 'sm'
  },
  isBorder: {
    //是否显示边框
    type: Boolean,
    default: false
  },
  padding: {
    type: [String, Number],
    default: 20
  }
})

const width = ref(defaultSize[props.size])
</script>

<style lang="less">
.arco-modal-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.define-modal.arco-modal {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  /* 自定义滚动 样式 */
  .arco-modal-body::-webkit-scrollbar {
    display: inline-block;
    width: 8px;
  }
  .arco-modal-body::-webkit-scrollbar-corner {
    display: none;
  }
  .arco-modal-body::-webkit-scrollbar-button {
    display: none;
  }

  .arco-modal-body::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: var(--color-neutral-4);
    border-radius: 4px;
  }

  .arco-modal-body::-webkit-scrollbar-track {
    display: none;
  }

  .arco-modal-body {
    flex: 1;
    max-height: calc(100vh - 155px);
    flex-shrink: 0;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 calc(1px * var(--padding));
    overflow: auto;
  }

  .arco-modal-header {
    padding: 20px;
    height: auto;
    border: 0;
    box-sizing: border-box;
    .arco-modal-title {
      font-weight: bold;
      color: var(--word-title);
    }
  }

  .arco-modal-footer {
    padding: var(--times-10);
    border: 0;
    padding-top: 0;
    box-sizing: border-box;
  }

  //modal 取消按钮样式
  .arco-modal-footer > .arco-btn-secondary {
    color: var(--font-color);
    background-color: #fff;
    border-color: var(--border-normal);
  }

  .arco-modal-footer > .arco-btn-secondary:hover {
    color: var(--primary-normal);
    background-color: #fff;
    border-color: var(--primary-normal);
  }

  .arco-modal-footer > .arco-btn-secondary:active {
    color: var(--primary-dark-color);
    background-color: #fff;
    border-color: var(--primary-dark-color);
  }
}
.define-modal.arco-modal:not(.arco-modal-fullscreen) {
  min-width: 520px;
  max-width: calc(100vw - 40px);
  min-height: 160px;
  max-height: calc(100vh - 40px);
}

.define-modal-border.define-modal.arco-modal {
  .arco-modal-body {
    flex: 1;
    max-height: calc(100vh - 155px);
    flex-shrink: 0;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 calc(1px * var(--padding));
    overflow: auto;
  }

  .arco-modal-header {
    padding: 20px;
    height: auto;
    border-bottom: 1px solid var(--border-normal);
  }

  .arco-modal-footer {
    padding: 20px;
    border-top: 1px solid var(--border-normal);
  }
}
</style>
