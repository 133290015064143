export default {
  mounted(el: any, binding: any) {
    console.log(el)
    const dom = el.getElementsByTagName('input')
    console.log(dom[0].value)
    // el.value = el.value?.replace(/\s/g, '')
    // binding.instance?.$emit?.('update:modelValue', el.value) // 通过v-model的'update:modelValue'事件更新绑定的值
  },
  updated(el: any, binding: any) {
    console.log(el)
    const dom = el.getElementsByTagName('input')
    el.getElementsByTagName('input')[0].value = dom[0].value?.replace(/\s+/g, '')
    binding.instance?.$emit?.('update:modelValue', dom[0].value) // 通过v-model的'update:modelValue'事件更新绑定的值
  }
}
