<template>
  <a-modal
    v-model:visible="visible"
    title="资源预览"
    closable
    :width="650"
    :footer="false"
    :mask-closable="false"
    ok-text="添加"
    @close="close"
    title-align="start"
    class="overflow-hideen addModal"
    modal-class="addModal">
    <div>
      <video
        ref="videoRef"
        v-if="props.urlType == 'video'"
        class="w-full"
        height="360"
        :src="props.fileUrl"
        controls
        muted
        autoplay></video>
      <audio
        ref="audioRef"
        v-if="props.urlType == 'audio'"
        :src="props.fileUrl"
        class="w-full bg-[#f1f3f4]"
        autoplay
        controls></audio>
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
import { onUnmounted, ref } from 'vue'
const props = defineProps({
  urlType: {
    type: String,
    default: ''
  },
  fileUrl: {
    type: String,
    default: ''
  }
})
const videoRef = ref()
const audioRef = ref()
const visible = ref(false)
const close = () => {
  videoRef.value && videoRef.value.pause()
  audioRef.value && audioRef.value.pause()
}
onUnmounted(() => {
  videoRef.value && videoRef.value.pause()
  audioRef.value && audioRef.value.pause()
})
</script>
