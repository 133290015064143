import Layouts from '@/layouts/Layout.vue'
export default [
  {
    path: '/',
    redirect: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE
  },

  {
    path: '/login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/401',
    component: () => import('@/views/error/401.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/error/404.vue')
  },
  // {
  //   path: '/',
  //   redirect: '/index',
  //   component: Layouts,
  //   children: [
  //     {
  //       path: '/index',
  //       component: () => import('@/views/index/index.vue'),
  //       name: 'Index',
  //       meta: { title: '我的首页', icon: 's-home', affix: true, concatPath: '/index' }
  //     }
  //   ]
  // },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/404.vue')
  }
  // {
  //   path: '/',
  //   redirect: '/template',
  //   component: Layouts,
  //   children: [
  //     {
  //       path: '/template',
  //       name: 'Template',
  //       component: () => import('@/views/template/index.vue')
  //     }
  //   ]
  // }
]
