import { ResponseData } from '@/interface/request'
import instance from '@/plugins/request'

export const login = (data: unknown): Promise<ResponseData | void> => {
  return instance.request({
    url: '/web/login',
    method: 'post',
    data
  })
}

// 退出方法
export const logout = (): Promise<ResponseData | void> => {
  return instance.request({
    url: '/logout',
    method: 'post'
  })
}

export const getInfo = (): Promise<ResponseData | void> => {
  return instance.request({
    url: '/getInfo',
    method: 'get'
  })
}

export const getRouters = (): Promise<ResponseData | void> => {
  return instance.request({
    url: '/getRouters',
    method: 'get'
  })
}

//获得SSO验证地址
export const getSSOUrl = (params: unknown): Promise<ResponseData | void> => {
  return instance.request({
    url: '/getSSOUrl',
    method: 'get',
    params,
    deleteAuthorization: true
  })
}
export const loginSSO = (data: unknown): Promise<ResponseData | void> => {
  return instance.request({
    url: '/loginSSO',
    method: 'post',
    data
  })
}
