/**
 * @module 一个组件使用一个文件夹文件夹下使用的vue文件的名称统一为index.vue
 */

// 获取当前目录下的所有子目录以及子目录下的所有文件
const allComp: any = import.meta.glob('./**/*.vue', { eager: true })

/**
 * @function toCamelCase 转换为大驼峰写法
 */
const toCamelCase = (str: string): string => {
  return str
    .replace(/[-_](.)/g, function (match, group1) {
      return group1.toUpperCase()
    })
    .replace(/^./, function (match) {
      return match.toUpperCase()
    })
}
//  一个文件夹 下一个index.vue 组件的情况
export default (app: any) => {
  try {
    for (const key in allComp) {
      const name = toCamelCase(key.split('/')[1])
      console.log(name, 'name')
      app.use({
        name: name,
        install(app: any): void {
          // 全局挂载
          app.component(name, allComp[key]?.default)
        }
      })
    }
  } catch (error) {
    console.log(error)
  }
}
