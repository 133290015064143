<template>
  <div
    class="bg-word-input-disabled rounded-md object-cover overflow-hidden"
    ref="loadImage"
    :class="str"
    :style="{ width: props.width + 'px', height: props.height + 'px' }"></div>
</template>

<script lang="ts" setup>
import { ref, onMounted, nextTick } from 'vue'
import defaultImage from '@/assets/images/user_default_img.svg'

const loadImage = ref()
const str = ref<string | undefined>()

const props = defineProps({
  src: {
    type: String,
    default: defaultImage
  },
  width: {
    type: Number,
    default: 60
  },
  height: {
    type: Number,
    default: 60
  }
})

const generateClass = () => {
  return 'load-image' + Math.floor(Math.random() * 1000)
}

const initPage = () => {
  str.value = generateClass()

  nextTick(() => {
    if (loadImage.value) {
      const dom = document.querySelector('.' + str.value)
      const image = new Image()
      image.src = props.src
      image.onload = () => {
        dom?.appendChild(image)
      }
      image.onerror = () => {
        image.src = defaultImage
        dom?.appendChild(image)
      }
    }
  })
}
onMounted(() => {
  initPage()
})
</script>
