import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getTerminalUrl } from '@/api'
import { Message } from '@arco-design/web-vue'
interface terminalUrlListType {
  indexCode: string
  systemName: string
  jumpUrl: string
  updateTime: string
}
export const appConfigStore = defineStore('appconfig', () => {
  const usersSocketUrl = ref('')
  const terminalUrlList = ref<terminalUrlListType[]>([])

  const init = async () => {
    try {
      if (import.meta.env.VITE_APP_ENV == 'development') {
        if (window.location.protocol == 'https:') {
          usersSocketUrl.value = import.meta.env.VITE_APP_BASE_API.replace('https', 'wss') + `/websocket/holder`
        } else {
          usersSocketUrl.value = import.meta.env.VITE_APP_BASE_API.replace('http', 'ws') + `/websocket/holder`
        }
      } else {
        if (window.location.protocol == 'https:') {
          usersSocketUrl.value = 'wss://' + window.location.host.replaceAll('https', 'wss') + `/websocket/holder`
        } else {
          usersSocketUrl.value = 'ws://' + window.location.host.replaceAll('http', 'ws') + `/websocket/holder`
        }
      }

      const res = await getTerminalUrl()
      if (res && res.code == 200) {
        terminalUrlList.value = res.data
      } else {
        Message.error('获取终端地址失败')
      }
    } catch (err) {
      console.log(err)
    }
  }
  return {
    init,
    usersSocketUrl,
    terminalUrlList
  }
})
