<template>
  <div
    class="text-times-7 px-times-6 py-times-4 box-border bg-primary-light-hover flex justify-between mb-times-10"
    v-if="tipVisible">
    <div class="text-primary-normal">
      <iconfont
        name="icon-xinxiquantianchong"
        class="mr-times-6"></iconfont>
      <slot></slot>
    </div>
    <div
      class="hover:box-border px-xxs hover:bg-bg-hover hover:rounded-50per text-center"
      @click="tipVisible = !tipVisible">
      <iconfont
        name="icon-icon_close"
        class="cursor-pointer"></iconfont>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const props = defineProps({
  close: {
    type: Boolean,
    default: true
  }
})

const tipVisible = ref(true)
</script>
