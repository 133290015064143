<template>
  <router-view />
</template>

<script setup lang="ts">
import registerStore from './store/register'
const register = registerStore()
window.addEventListener('error', register.errorListener, true)
register.registerColor()
register.registerTheme()
register.registerSpace()
</script>
