/**
 * 使用指令
 * 1.只针对进入时/离开时/更新时（单个）,使用默认动画
 * <div v-animation="{opportunity:'enter'}"></div>
 * 2.只针对进入时/离开时/更新时（单个）,使用指令内动画
 * <div v-animation="{opportunity:'update',name:'fade-in-up'}"></div>
 * 3.只针对进入时/离开时/更新时（单个）,使用自定义动画
 * <div v-animation="{opportunity:'leave',styleConfig:{animation: rotateIn 2s forwards; }"></div>
 * 在animation.less文件中写入rotateIn的关键帧
 * 4.针对多个时期，使用默认动画
 * <div v-animation="{opportunity:['enter','leave']}"></div>
 * 5.针对多个时期，使用指令内动画，参数对应即可
 * <div v-animation="{opportunity:['enter','leave'],name:['fade-in-up','fade-out-up']}"></div>
 * 6.针对多个时期，使用自定义动画
 * <div v-animation="{opportunity:['enter','leave'],styleConfig:[{animation: rotateIn 2s forwards; },{animation: rotateOut 2s forwards; }]}"></div>
 *
 * 必传参数opportunity
 * 非必传参数name,styleConfig,duration
 *
 * duration用于控制所有动画的持续时间，number类型，单位ms,默认为1000ms
 */

import { DirectiveBinding } from 'vue'

type Status = 'enter' | 'leave' | 'update' // 进入时，离开时，更新时
type StyleObj = {
  [key: string]: string
}
// 传参的类型
type Animation = {
  // 动画时机'enter',['enter'],['leave'],['update'],['enter','leave']...
  // enter,update,leave分别为进入时，离开时，更新时应用动画，可以用字符串，也可用数组组合使用
  opportunity: string[] | Status

  /**
   * 内置动画名称
   * 添加内置动画流程：在animation.less文件中添加动画，类名即可作为name属性
   *
   * 现有内置动画(以下类名对应的动画均来自animate.css动画库)
   * fade-in-up fade-out-up
   * zoom-in zoom-out fade-out fade-in flipInX
   * 不传name时默认动画为bounce，传了styleConfig时，name属性无效
   * 给不同的周期传入不同动画，需要name数组和opportunity数组相对应
   */
  name?: string | string[]

  // 自定义样式动画，@keyframes可在animation.less中定义
  // 注意：当出传入该配置时，默认动画会失效，使用传入的动画样式
  styleConfig?: StyleObj | StyleObj[]

  // 动画持续时间 单位ms
  duration?: number
}

// 公共函数
const animationFn = (el: HTMLElement, animationClass: string) => {
  // 在元素插入到 DOM 之前添加进入动画效果的类
  el.classList.add('animated', animationClass)
  // 在下一个 DOM 更新周期中移除动画类，以便能够在卸载时重新添加
  const onAnimationEnd = () => {
    el.classList.remove('animated', animationClass)
    el.removeEventListener('animationend', onAnimationEnd)
  }
  el.addEventListener('animationend', onAnimationEnd)
}
// 处理传入的样式与name
const handleAnimation = (val: Animation, el: HTMLElement, index: number) => {
  let animationClass
  // 修改动画持续时间
  if (val.duration) {
    document.documentElement.style.setProperty('--animate-duration', `${val.duration / 1000}s`)
  }
  // 传入样式对象与name处理
  if (!val.styleConfig) {
    if (!val.name) {
      animationClass = 'bounce'
      animationFn(el, animationClass)
    } else if (typeof val.name === 'string') {
      animationClass = val.name
      animationFn(el, animationClass)
    } else if (Array.isArray(val.name)) {
      animationClass = val.name[index]
      animationFn(el, animationClass)
    }
  } else if (Array.isArray(val.styleConfig)) {
    val.styleConfig.forEach((item: StyleObj): void => {
      Object.keys(item).forEach((key: string): void => {
        ;(el.style as any)[key] = item[key]
      })
    })
  } else if (Object.prototype.toString.call(val.styleConfig) === '[object Object]') {
    Object.keys(val.styleConfig).forEach((item: string): void => {
      ;(el.style as any)[item] = (val.styleConfig as StyleObj)[item]
    })
  }
}

// 钩子函数公共部分
const commonHook = (val: Animation, el: HTMLElement, hook: string) => {
  const opportunity = val.opportunity
  const index = hook === 'enter' ? 0 : hook === 'update' ? 1 : 2
  if (typeof opportunity === 'string') {
    if (opportunity === hook) {
      handleAnimation(val, el, index)
    }
  } else if (Array.isArray(opportunity)) {
    if (!val.opportunity.includes(hook)) {
      return
    }
    handleAnimation(val, el, index)
  }
}
export default {
  beforeMount(el: HTMLElement, binding: DirectiveBinding<Animation>): void {
    // 根据传入的参数决定动画效果
    commonHook(binding.value, el, 'enter')
  },
  beforeUpdate(el: HTMLElement, binding: DirectiveBinding<Animation>): void {
    commonHook(binding.value, el, 'update')
  },
  beforeUnmount(el: HTMLElement, binding: DirectiveBinding<Animation>): void {
    commonHook(binding.value, el, 'leave')
    // 设置一个定时器，在动画结束后移除元素
    const timer = setTimeout(() => {
      el.remove()
    }, binding.value?.duration || 1000) // 默认动画持续时间为 1 秒
    clearTimeout(timer)
  }
}
