import { removeSession } from './../utils/auth'
import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Message, Notification } from '@arco-design/web-vue'
import { getToken, removeToken } from '@/utils/auth'
import { tansParams } from '@/utils/methods'
import { routerListName } from '@/store/component/top-tags'
import { getQueryString } from '@/utils/methods'
import { redirectName } from '@/static'
import { whiteList, qiweiWhiteList } from '@/router//permission'

const TIMEOUT = 60000
/**
 * @class DefineRequest:请求单例类
 * @constructor
 * @public @static getInstance
 * @returns {DefineRequest} DefineRequest类的单例对象
 * @static interceptors：为axios单例对象添加拦截
 * @public @static getConfig
 * @param {AxiosRequestConfig} config：请求配置
 * type AxiosRequestConfig extends AxiosRequestConfig {
 *  timeout: number //该请求需要延迟多少时间
 * }
 * @returns {AxiosRequestConfig} 拼接参数和权限返回回来后的数据
 * @default request
 * @param {AxiosRequestConfig} 传入拼接参数和权限返回回来后的数据
 * @returns {Promise<AxiosResponse | void>} 返回promise对象
 * @private isSameData
 * @param {AxiosRequestConfig} config: url相关配置
 * @returns {boolean}  // true: 不存在重复  false:存在重复
 *
 *
 */
class DefineRequest {
  public static instance: DefineRequest
  private axiosInstance: AxiosInstance
  private static cancelTokenSources: Map<string, AxiosRequestConfig>
  private constructor() {
    // 私有构造函数，确保不能直接实例化类
    this.axiosInstance = axios.create()
    DefineRequest.interceptors(this.axiosInstance)
    DefineRequest.cancelTokenSources = new Map()
  }

  public static getInstance(): DefineRequest {
    if (!DefineRequest.instance) {
      DefineRequest.instance = new DefineRequest()
    }
    return DefineRequest.instance
  }

  public static interceptors(instance: AxiosInstance) {
    instance.interceptors.request.use((req: AxiosRequestConfig) => {
      return req
    })
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        // debugger
        if (res.status === 200) {
          if (res.config.url) {
            //将url为这个请求的从map中删除
            DefineRequest.cancelTokenSources.delete(res.config.url)
          }

          if (
            res.data.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            res.data.type == 'application/json' ||
            res.data.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
          ) {
            return res.data
          }
          if (res.data.code === 200) {
            return res.data
          } else {
            if (res.data.code === 401) {
              Message.warning({
                id: 'message',
                content: '登录信息已过期,请重新登录'
              })
              removeToken()
              removeSession(routerListName)
              // 静态白名单允许，且全局配置项的白名单允许
              const isGotoQiwei = window._globalConfig?.ssoType && window._globalConfig?.ssoType != 'itb'
              window.location.reload() //触发全局路由拦截的重定向逻辑
              throw new Error('系统访问权限失败')
            } else if (res.data.code === 404) {
              if (!window.location.href.includes('404')) {
                window.location.href = '/#/404'
              }
            } else {
              // 网络状态没问题，后台接口状态没问题，后台返回状态码不是200
              return res.data
            }
            return
          }
        } else {
          // 网络状态没问题，后台接口状态不对
          Notification.error(res.statusText)
        }
      },
      (error) => {
        let { message } = error
        if (message == 'Network Error') {
          message = '连接异常，请联系用户处理，错误码:[Network Error]'
        } else if (message.includes('timeout')) {
          message = '连接超时，请稍后尝试'
        } else if (message.includes('Request failed with status code')) {
          message = '未知异常，请联系用户处理，错误码' + message.substr(message.length - 3)
        }
        Notification.error({
          content: message
        })
        return Promise.reject(error)
      }
    )
  }

  public static getConfig(config: AxiosRequestConfig | any): AxiosRequestConfig {
    const base: any = {
      ...config,
      baseURL: window.VITE_APP_BASE_API || import.meta.env.VITE_APP_BASE_API,
      timeout: config.timeout || TIMEOUT
    }
    base.headers = {
      'Content-Type': 'application/json;charset=utf-8',
      ...(config?.headers && { ...config.headers }),
      Authorization: getToken()
    }

    // 根据若依的数组在get/delete请求中传参进行转化进行的迁移逻辑
    if ((base.method?.toLowerCase() === 'get' || base.method?.toLowerCase() === 'delete') && base.params) {
      let url = base.url + '?' + tansParams(base.params)
      url = url.slice(0, -1)
      base.params = {}
      base.url = url
    }
    // 将deleteAuthorization传入，控制是否不传Authorization
    if (config.deleteAuthorization && base.headers) {
      if ('Authorization' in base.headers) {
        delete base.headers['Authorization']
      }
      if ('deleteAuthorization' in base.headers) {
        delete base['deleteAuthorization']
      }
    }
    return base
  }

  private isSameData(config: AxiosRequestConfig): boolean {
    // true: 不存在重复  false:存在重复
    if (!config.url) return false
    if (!DefineRequest.cancelTokenSources.size) {
      return true
    }
    if (!DefineRequest.cancelTokenSources.has(config.url)) {
      return true
    }

    let isCommon = true
    const _run = (data1: { [x: string]: any }, data2: { [x: string]: any }) => {
      for (const key in data1) {
        for (const key2 in data2) {
          if (typeof data2[key2] == 'object') {
            _run(data1[key], data2[key2])
          } else {
            if (key == key2 && data1[key] == data2[key2]) {
              isCommon = false
            }
          }
        }
      }
    }
    if (DefineRequest.cancelTokenSources.has(config.url)) {
      if (config.method?.toLocaleLowerCase() == 'get') {
        if (!config.params) {
          return false
        } else {
          _run(config.params, DefineRequest.cancelTokenSources.get(config.url)?.params)
          return isCommon
        }
      } else {
        if (config.data) {
          _run(config.data, DefineRequest.cancelTokenSources.get(config.url)?.data)
          return isCommon
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }

  request(config: AxiosRequestConfig | any): Promise<AxiosResponse | void> {
    const resultsConfig = DefineRequest.getConfig(config)
    const isCommon = this.isSameData(resultsConfig)
    if (resultsConfig.url) {
      if (!isCommon && DefineRequest.cancelTokenSources.size) {
        DefineRequest.cancelTokenSources.delete(resultsConfig.url)
        return new Promise((resolve, _reject) => resolve())
      }
      DefineRequest.cancelTokenSources.set(resultsConfig.url, resultsConfig)
    }
    return this.axiosInstance.request(resultsConfig)
  }
}

const singleton = DefineRequest.getInstance()

export default singleton
