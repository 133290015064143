import hasPermi from './component/hasPermi'
import hasRole from './component/hasRole'
import hasDisabled from './component/hasPermiDisabled'
import trimmed from './component/trimmed'
import animationEffect from './component/animationEffect'
import { App } from 'vue'

export default function directive(app: App) {
  app.directive('hasPermi', hasPermi)
  app.directive('hasRole', hasRole)
  app.directive('hasDisabled', hasDisabled), app.directive('trimmed', trimmed), app.directive('animation', animationEffect)
}
