import { createApp, ref } from 'vue'
import './polyfills'
import 'nprogress/nprogress.css'
import router from '@/router/index'
import '@/router/permission'
import dayjs from '@/plugins/day'
import 'virtual:svg-icons-register'
import '@/assets/styles/index.less'
import '@/assets/iconfont/iconfont.js'
import Cookies from 'js-cookie'
import { useDict } from '@/utils/dict'
import directive from '@/directives' // directive
import App from './App.vue'
import modal from '@/utils/modal'
import store from '@/store/store'
import matomo from '@/plugins/vue-matomo'

import allComp from '@/component/index'
const cApp: any = createApp(App)

// 全局方法挂载
cApp.config.globalProperties.useDict = useDict
cApp.config.globalProperties.modal = modal

cApp.config.globalProperties.dayjs = dayjs
cApp.config.globalProperties.cookie = Cookies
cApp.use(store).use(router)

if ((window.VITE_APP_MATOMO_ADD && window.VITE_APP_MATOMO_SID) || import.meta.env.VITE_APP_MATOMO_ADD || import.meta.env.VITE_APP_MATOMO_SID) {
  matomo(cApp, router)
}

directive(cApp)
// component 目录下组件注册引入
allComp(cApp)

cApp.mount('#app')
window.ref = ref
