<template>
  <div
    class="transition-all h-full w-full"
    :class="visible ? 'flex gap-times-4' : 'w-full flex relative '">
    <div
      v-if="!visible"
      class="absolute -left-times-0">
      <a-tooltip :content="visible ? '收起' : '展开'">
        <div
          class="cursor-pointer bg-white p-times-2 box-border rounded-times-1 hover:bg-bg-hover"
          @click=";(visible = !visible), emits('change', visible)">
          <i class="iconfont icon-xiangyou"></i>
        </div>
      </a-tooltip>
    </div>

    <div
      class="flex-shrink-0 bg-white duration-300 h-full overflow-hidden box-border"
      :class="visible ? ' w-[348px]  relative ' : 'w-0 h-0'">
      <div class="w-[348px] h-full box-border pt-times-10 px-times-8">
        <div class="flex justify-between items-center">
          <div class="flex-1">
            <slot name="leftTop"></slot>
          </div>

          <a-tooltip :content="visible ? '收起' : '展开'">
            <div
              :class="visible ? ' cursor-pointer hover:bg-bg-hover rounded-times-1 box-border p-times-2 ml-xs' : ''"
              @click=";(visible = !visible), emits('change', visible)">
              <i class="iconfont icon-xiangzuo"></i>
            </div>
          </a-tooltip>
        </div>
        <a-divider :margin="12"></a-divider>
        <div class="h-full bg-white w-full">
          <slot name="leftBottom"></slot>
        </div>
      </div>
    </div>
    <div
      class="h-full flex-1 grid grid-cols-1"
      :class="visible ? '' : ' ml-times-16'">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
const emits = defineEmits(['change'])

const visible = ref(true)
</script>
