<template>
  <div class="w-full">
    <div class="w-full flex justify-between relative">
      <div
        class="flex-1 animation-test"
        :class="isExpand.value ? 'overflow-hidden' : ''">
        <div ref="expandRef">
          <div class="form-expand-content"><slot></slot></div>
        </div>
      </div>
      <div
        class="flex"
        :class="isExpand.status ? 'items-end' : 'items-start'">
        <div class="flex">
          <div
            v-show="typeof isExpand.value == 'number' && (isExpand.value > 50 || originHeight > 50)"
            @click="toggleExpand"
            class="flex items-center text-word-subtitle cursor-pointer mr-sm">
            {{ isExpand.status ? '收起' : '展开' }}
            <i
              class="iconfont ml-xxs text-times-6"
              :class="isExpand.status ? 'icon-icon_arrow-up' : 'icon-icon_arrow-doen'"></i>
          </div>
          <a-button
            class="mr-sm"
            @click="emits('reset', isExpand.status)"
            type="primary">
            重置
          </a-button>
          <a-button @click="emits('refresh')">
            <template #icon>
              <i class="iconfont icon-shuaxin"></i>
            </template>
          </a-button>
        </div>
      </div>
    </div>
    <a-divider
      v-if="props.border"
      :margin="props.margin"></a-divider>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, ref, reactive, onBeforeUnmount } from 'vue'

const props = defineProps({
  // 默认是否展开
  expand: {
    type: Boolean,
    default: true
  },
  border: {
    type: Boolean,
    default: true
  },
  margin: {
    type: Number,
    default: 12
  }
})
const emits = defineEmits(['reset', 'refresh', 'expand'])

const isExpand = reactive({
  status: props.expand, // true: 当前是展开状态 false: 当前是收起状态
  value: 0
})

/** 初始高度 */
const originHeight = ref(0)

const expandRef = ref()

/**
 * @function toggleExpand
 * 根据状态切换展开隐藏，展开隐藏动效使用DOM下的新api:animate函数，优化性能
 * */
const toggleExpand = () => {
  nextTick(() => {
    isExpand.status = !isExpand.status
    let obj = [{ height: originHeight.value + 'px' }, { height: '33px' }] //折叠的样式
    if (isExpand.status) {
      obj = [{ height: '33px' }, { height: originHeight.value + 'px' }]
    }
    expandRef.value.animate(obj, { duration: 300, iterations: 1, easing: 'ease-out', fill: 'forwards' })
    console.log(originHeight.value)
  })
}

const changeSize = () => {
  /**
   * 2个dom元素，通过.form-expand-content可以判断到最新最真实无虚拟dom延迟的scrollHeight，
   * 通过expandRef.value去设置元素动画，解决虚拟dom导致的scrollHeight延迟问题
   * */

  nextTick(() => {
    const el = document.querySelector('.form-expand-content')
    if (el) {
      isExpand.value = (el?.scrollHeight || expandRef.value.scrollHeight) + 1
      originHeight.value = (el?.scrollHeight || expandRef.value.scrollHeight) + 1
      // TODO: 调用toggleExpand() ,保证isExpand.status不会变化
      props.expand && (isExpand.status = !isExpand.status)
    }
    props.expand && toggleExpand()

    console.log(el?.scrollHeight, expandRef.value.scrollHeight, '333')
  })
}

onMounted(() => {
  nextTick(() => {
    // 保证接口数据更新dom后，初始dom高度更新后的高度
    setTimeout(() => {
      changeSize()
    }, 500)
    window.addEventListener('resize', changeSize)
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', changeSize)
})
</script>
<style>
.animation-test {
  animation-fill-mode: forwards;
}
</style>
