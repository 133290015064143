import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
dayjs.extend(localeData)

// const weekWordLst = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']

export const redirectName = 'redirect'

export const groupOptions = [
  { label: '默认', value: 'DEFAULT' },
  { label: '系统', value: 'SYSTEM' }
]

export const statusOptions = [
  { label: '正常', value: '0' },
  { label: '暂停', value: '1' }
]
export const logStatusOptions = [
  { label: '成功', value: '0' },
  { label: '失败', value: '1' }
]

export const concurrentOptions = [
  { label: '允许', value: '0' },
  { label: '禁止', value: '1' }
]

export const misfirePolicyOptions = [
  { label: '立即执行', value: '1' },
  { label: '执行一次', value: '2' },
  { label: '放弃执行', value: '3' }
]

export const menuTypeOptions = [
  { label: '目录', value: 'M' },
  { label: '菜单', value: 'C' },
  { label: '按钮', value: 'F' }
]
export const isFrameOptions = [
  { label: '是', value: '0' },
  { label: '否', value: '1' }
]
export const expandOptions = [
  { label: '展开', value: '0' },
  { label: '折叠', value: '1' }
]
export const fastEntryOptions = [
  { label: '设为WEB端快捷入口', value: '1' },
  { label: '设为移动端快捷入口', value: '2' },
  { label: '不设为快捷入口', value: '3' }
]
export const isBuriedPointOptions = [
  { label: '跟踪页面', value: '0' },
  { label: '不跟踪页面', value: '1' }
]

export const isTabOptions = [
  { label: '设为移动端tab栏', value: '0' },
  { label: '不设为移动端tab栏', value: '1' }
]
export const channelOptions = [
  { label: 'WEB端', value: '1' },
  { label: '移动端', value: '2' }
]

export const isCacheOptions = [
  { label: '缓存', value: '0' },
  { label: '不缓存', value: '1' }
]
export const visibleOptions = [
  { label: '显示', value: '0' },
  { label: '隐藏', value: '1' }
]
export const timeOptions = [
  {
    label: '秒',
    value: '0',
    model: 'seconds'
  },
  {
    label: '分钟',
    value: '1',
    model: 'minutes'
  },
  {
    label: '小时',
    value: '2',
    model: 'hour'
  },
  {
    label: '日',
    value: '3',
    model: 'day'
  },
  {
    label: '月',
    value: '4',
    model: 'month'
  },
  {
    label: '周',
    value: '5',
    model: 'week'
  },
  {
    label: '年',
    value: '6',
    model: 'year'
  }
]

export const toggleOptions: Array<{ width?: string; label: string; value: string; model: string; default: string }> = [
  {
    label: '秒',
    value: '0',
    model: 'seconds',
    default: '*'
  },
  {
    label: '分钟',
    value: '1',
    model: 'minutes',
    default: '*'
  },
  {
    label: '小时',
    value: '2',
    model: 'hour',
    default: '*'
  },
  {
    label: '日',
    value: '3',
    model: 'day',
    default: '*'
  },
  {
    label: '月',
    value: '4',
    model: 'month',
    default: '*'
  },
  {
    label: '周',
    value: '5',
    model: 'week',
    default: '?'
  },
  {
    label: '年',
    value: '6',
    model: 'year',
    default: ''
  },
  {
    label: 'Cron表达式',
    value: '7',
    default: '*****?',
    model: 'express',
    width: '150px'
  }
]

export const secondsList = ((): Array<{
  label: string | number
  value: string | number
}> => {
  return Array.from({ length: 60 }, (_v, i) => ({ label: i + '秒', value: i }))
})()
export const minuteList = ((): Array<{
  label: string | number
  value: string | number
}> => {
  return Array.from({ length: 60 }, (_v, i) => ({ label: i + '分', value: i }))
})()
export const hourList = ((): Array<{
  label: string | number
  value: string | number
}> => {
  return Array.from({ length: 24 }, (_v, i) => ({ label: i + '时', value: i }))
})()
export const dayList = ((): Array<{
  label: string | number
  value: string | number
}> => {
  return Array.from({ length: dayjs().daysInMonth() }, (_v, i) => ({
    label: i + 1 + '号',
    value: i
  }))
})()

export const weekList = ((): Array<{
  label: string | number
  value: string | number
}> => {
  return dayjs.weekdays(true).map((el, index) => ({ label: el, value: index }))
})()
export const monthList = ((): Array<{
  label: string | number
  value: string | number
}> => {
  return dayjs.months().map((el, index) => ({ label: el, value: index }))
})()
export const yearList = ((): Array<{
  label: string | number
  value: string | number
}> => {
  return Array.from({ length: 9 }, (v, i) => ({
    label: Number(dayjs().format('YYYY')) + i + '年',
    value: Number(dayjs().format('YYYY')) + i
  }))
})()
