import { updateMonitoringImage } from '@/api/videoapi/video'
import axios from 'axios'
import { onBeforeUnmount, onMounted } from 'vue'

export const handleTree = ({ data, parentIdName, childrenName, children, name, filter }: any): any => {
  const config: {
    parentIdName: string
    childrenName: string
    children: string
    name: string
    filter: boolean
  } = {
    parentIdName: parentIdName || 'parentId',
    childrenName: childrenName || 'menuId',
    children: children || 'children',
    name: name || 'menuName',
    filter: filter || false
  }

  const list = data.reduce((prev: any, el: any) => {
    if (config.filter) {
      if (prev && prev[el[parentIdName]]) {
        prev[el[parentIdName]].push({
          [childrenName]: el[childrenName],
          [children]: el[children],
          [name]: el[name]
        })
      } else {
        prev[el[parentIdName]] = [
          {
            [childrenName]: el[childrenName],
            [children]: el[children],
            [name]: el[name]
          }
        ]
      }
    } else {
      if (!(el.children && el.children.length)) {
        delete el.children
      }
      if (prev && prev[el[parentIdName]]) {
        prev[el[parentIdName]].push(el)
      } else {
        prev[el[parentIdName]] = [el]
      }
    }
    if (!(el.children && el.children.length)) {
      delete el.children
    }
    return prev
  }, {})

  const keyArr: Array<string> = Object.keys(list)
  keyArr.forEach((str: string) => {
    list[str].forEach((el: any) => {
      if (keyArr.join(',').includes(el[config.childrenName])) {
        el[config.children] = list[el[config.childrenName]]
      }
    })
  })

  data.forEach((el: { [x: string]: string }) => {
    if (el[config.childrenName] in list) {
      delete list[el[config.childrenName]]
    }
  })

  const arr = []
  for (const k in list) {
    arr.push(...list[k])
  }

  return arr
}

/** 排队执行promise **/

export const queuePromise = (proms: any) => {
  let res: any = undefined,
    rej: any = undefined
  const pro = new Promise((resolve, reject) => {
    res = resolve
    rej = reject
  })

  const result: any[] = []
  let count = 0
  let fullfilledCount = 0
  for (const prom of proms) {
    const i = count
    count++
    Promise.resolve(prom).then((data) => {
      result[i] = data
      fullfilledCount++
      if (fullfilledCount === count) {
        res({ result, count, fullfilledCount })
      }
    }, rej)
  }
  if (count === 0) {
    res({ result: [], count, fullfilledCount })
  }

  return pro
}

export const loadImage = (url: unknown) => {
  return new Promise((resolve, reject) => {
    const image: any = new Image()
    image.onload = async () => {
      resolve({
        width: image.width,
        height: image.height,
        src: image.currentSrc,
        image: image
      })
    }
    image.onerror = reject
    image.src = url
  })
}

const base64ConvertFile = function (urlData: string, filename = 'filename') {
  const arr: any = urlData.split(',')
  const type = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, {
    type: type
  })
}

export const loadReader = (file: Blob) => {
  return new Promise((resolve, reject) => {
    if (window.FileReader) {
      const read = new FileReader()
      read.readAsDataURL(file)

      read.onload = ({ target }: any) => {
        resolve(target.result)
      }
      read.onerror = reject
    }
  })
}

/** 压缩图片 **/

export const compress = (file: { size: number; raw: File; name: string; image: string }, size = 1024 * 1024 * 10, quality = 0.8, width: any = 1080) => {
  return new Promise((resolve, reject) => {
    try {
      if (file.size > size) {
        loadReader(file.raw).then((result) => {
          loadImage(result)
            .then((img: any) => {
              const canvas = document.createElement('canvas')

              const ctx: any = canvas.getContext('2d')
              if (img.width > width) {
                const radio: any = Number((width / img.width).toFixed(2))

                const height: any = img.height * radio.toFixed(1)
                canvas.setAttribute('width', width)
                canvas.setAttribute('height', height)
                ctx.drawImage(img.image, 0, 0, width, height)
                const base64 = canvas.toDataURL(file.raw.type, 1)
                file.raw = base64ConvertFile(base64, file.name)
                file.size = file.raw.size
                file.image = base64
                resolve(file)
                return
              } else {
                canvas.setAttribute('width', img.width)
                canvas.setAttribute('height', img.width)
                ctx.drawImage(img.image, 0, 0, img.width, img.width)
                const base64 = canvas.toDataURL(file.raw.type, quality)
                file.raw = base64ConvertFile(base64, file.name)
                file.size = file.raw.size
                file.image = base64
                resolve(file)
                return
              }
            })
            .catch((error) => {
              reject(error)
            })
        })
      } else {
        loadReader(file.raw).then((result: any) => {
          file.image = result
          resolve(file)
          return
        })
      }
    } catch (error) {
      reject(error)
    }
  })
}
//  file: 文件  quality: 压缩质量  width: 图片宽度 hopeSize: 期望大小(字节)
export const compressW = (file: any, quality = 0.8, width: any = 1080, hopeSize: any = 600000) => {
  return new Promise((resolve, reject) => {
    try {
      loadReader(file).then((result) => {
        loadImage(result)
          .then((img: any) => {
            let originalSize = file.size
            const canvas = document.createElement('canvas')
            const ctx: any = canvas.getContext('2d')
            if (img.width > width) {
              // console.log('图片大于' + width)
              const radio: any = Number((width / img.width).toFixed(2))
              const height: any = img.height * radio.toFixed(1)
              canvas.setAttribute('width', width)
              canvas.setAttribute('height', height)
              ctx.drawImage(img.image, 0, 0, width, height)
              const base64 = canvas.toDataURL(file.type, quality)
              let photo = dataURLtoFile(base64, file.name)
              if (hopeSize) {
                if (photo.size >= hopeSize) {
                  console.log('%c 有期望值多次  ' + originalSize + ' ==>  ' + photo.size, 'background: greenyellow; color: #222')
                  for (let i = 0; i < 10; i++) {
                    const base64 = canvas.toDataURL(file.type, quality > 0.2 ? (quality -= 0.1) : 0.1)
                    let photo = dataURLtoFile(base64, file.name)
                    if (photo.size < hopeSize || quality < 0.2) {
                      // console.log(quality, 'quality')
                      resolve(photo)
                      return
                    }
                    // console.log("quality", quality);
                  }
                } else {
                  console.log('%c 有期望值单次  ' + originalSize + ' ==>  ' + photo.size, 'background: greenyellow; color: #222')
                  resolve(photo)
                }
              } else {
                console.log('%c 无期望值  ' + originalSize + ' ==>  ' + photo.size, 'background: greenyellow; color: #222')
                resolve(photo)
              }

              return
            } else {
              console.log('图片小于' + width)
              canvas.setAttribute('width', img.width)
              canvas.setAttribute('height', img.height)
              ctx.drawImage(img.image, 0, 0, img.width, img.height)
              const base64 = canvas.toDataURL(file.type, quality)
              console.log(base64, '输出大小')
              let photo = dataURLtoFile(base64, file.name)
              resolve(photo)
              return
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    } catch (error) {
      reject(error)
    }
  })
}

// 跟据当前监控主机判断进入哪个监控主机的项目

// export const linkToPoint = () => {
//   const mobileEquipment = /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile|kfapwi|playbook/i
//   const port = window.location.port || location.port
//   const protocol = window.location.protocol || location.protocol
//   const userAgent = window.navigator.userAgent
//   const mobile = import.meta.env.VITE_APP_MOBILE_PORT
//   const pc = import.meta.env.VITE_APP_PC_PORT
//   const mobileLocation = window.location.protocol + '//' + window.location.hostname + ':' + import.meta.env.VITE_APP_MOBILE_PORT
//   const pcLocation = window.location.protocol + '//' + window.location.hostname + ':' + import.meta.env.VITE_APP_PC_PORT
//   const equip = getStorage('equip') //1:移动端  2:PC端

//   if (userAgent) {
//     if (mobileEquipment.test(userAgent.toLowerCase())) {
//       if (port) {
//         if (port != mobile) {
//           location.assign(mobileLocation)
//         }
//       } else {
//         if (equip != '1') {
//           setStorage('equip', 1)
//           location.assign(mobileLocation)
//         }
//       }
//     } else {
//       if (port) {
//         if (port != pc) {
//           location.assign(pcLocation)
//         }
//       } else {
//         if (equip != '2') {
//           setStorage('equip', 2)
//           location.assign(pcLocation)
//         }
//       }
//     }
//   } else {
//     if (port) {
//       if (port != mobile) {
//         location.assign(mobileLocation)
//       }
//     } else {
//       if (protocol === 'https:' && equip != '1') {
//         setStorage('equip', 1)
//         location.assign(mobileLocation)
//       } else if (protocol === 'http:' && equip != '2') {
//         setStorage('equip', 2)
//         location.assign(pcLocation)
//       }
//     }
//   }
// }

/**
 * @Function dataURLtoFile  base64转file对象
 * @param { string } dataUrl 返回的Base64数据
 * @param { string } fileName 文件名
 *
 * */
export const dataURLtoFile = (dataUrl: string, fileName: string) => {
  var arr: any = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], fileName, { type: mime })
}

// 检测传入地址是否可访问
export const checkUrlbyAxios = (url: string) => {
  return new Promise((resolve, reject) => {
    try {
      axios({
        url: url + '?time=' + new Date().valueOf(),
        method: 'get',
        timeout: 2000
      })
        .then((res) => {
          // console.log(res, 'res')
          resolve(true)
        })
        .catch((error) => {
          console.log(error.toJSON(), 'json')
          if (error.toJSON() && error.toJSON().message == 'timeout of 2000ms exceeded') {
            resolve(false)
          }
          if (error.toJSON() && error.toJSON().message == 'Request failed with status code 404') {
            resolve(false)
          }
          if (error.toJSON() && error.toJSON().message == 'Network Error') {
            resolve(true)
          }
        })
    } catch (error) {
      reject(error)
    }
  })
}

// 自动上传图片
export const uploadImage = (file: any, indexCode: string) => {
  try {
    let formData = new FormData()
    formData.append('file', file)
    updateMonitoringImage(indexCode, formData)
  } catch (err) {
    console.log(err)
  }
}

// 判断手机/电脑
export const getUserDeviceType = () => {
  try {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return 'mobile'
    } else {
      return 'desktop'
    }
  } catch (err) {
    console.log(err)
  }
}

//根据时间自动返回问候语
export const getTimeState = () => {
  // 获取当前时间
  let timeNow = new Date()
  // 获取当前小时
  let hours = timeNow.getHours()
  // 设置默认文字
  let text = ``
  // 判断当前时间段
  if (hours >= 0 && hours <= 10) {
    text = `早上好!`
  } else if (hours > 10 && hours <= 11) {
    text = `上午好!`
  } else if (hours > 11 && hours <= 14) {
    text = `中午好!`
  } else if (hours > 14 && hours <= 18) {
    text = `下午好!`
  } else if (hours > 18 && hours <= 24) {
    text = `晚上好!`
  }
  // 返回当前时间段对应的状态
  return text
}

// 定时执行函数
export const timingFun = (fn: () => void, delay: number) => {
  onMounted(() => {
    fn() // 初始化时立即执行一次
    const timer = setInterval(fn, delay) // 每隔指定的时间执行一次
    // 在组件卸载时清除定时器
    onBeforeUnmount(() => {
      console.log('函数已卸载 ')
      clearInterval(timer)
    })
  })
}

// 防抖
export const debounce = (fn: Function, delay: number) => {
  let timer: ReturnType<typeof setTimeout>
  return (...args: any[]) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

// 节流
export const throttle = (fn: Function, delay: number) => {
  let canRun = true
  let timeout: number | null = null
  return (...args: any[]) => {
    if (canRun) {
      fn(...args)
      canRun = false
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => {
        canRun = true
      }, delay)
    }
  }
}
