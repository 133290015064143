import localforage from 'localforage'
import { getSession, getStorage, setSession, setStorage, removeStorage, removeSession } from '@/utils/auth'
import storageJSON from '@/store/storage.json'
import { PiniaPluginContext } from 'pinia'

const storage: any = storageJSON

export type dbTypes = 'session' | 'local' | 'indexedDB'

export interface PersistStrategy {
  key?: string
  storage?: Storage
  paths?: string[]
}
export interface PersistOptions {
  type?: dbTypes | 'session'
}
declare module 'pinia' {
  interface DefineStoreOptionsBase<S, Store> {
    persist?: PersistOptions | Boolean
  }
}
// pinia的state的实例存储，主要用来做数据清除
const storeAllState: any = {}

/**
 * @function clearAlL
 * 清空所有缓存的store数据，如果传入type，则只清空该类型下的所有store数据，如果不传入，则清空所有支持的数据
 * @param {dbTypes} type 传入需要清空所有数据的类型
 * */
export const clearAlL = (type?: dbTypes) => {
  for (const key in storage) {
    if (type) {
      removeStorageItem(key, type)
    } else {
      removeStorageItem(key, 'indexedDB')
      removeStorageItem(key, 'local')
      removeStorageItem(key, 'session')
    }
  }
}

/**
 * @function removeItemFromPath 清空传入的path页面的对应store数据
 * @param {string} path 传入页面的path路径
 * */

export const removeItemFromPath = (path: string, type?: dbTypes) => {
  if (storage) {
    for (const key in storage) {
      const el = storage[key]
      if (el?.length) {
        for (let i = 0; i < el.length; i++) {
          if (el[i] === path) {
            if (key in storeAllState) {
              storeAllState[key].$reset()
            }
            if (type) {
              removeStorageItem(key, type)
            } else {
              removeStorageItem(key, 'indexedDB')
              removeStorageItem(key, 'local')
              removeStorageItem(key, 'session')
            }
          }
        }
      }
    }
  }
}

/**
 * @function removeItem 移除传入的store名称对应的缓存数据
 * @param {string} name 传入store名称
 * @param {dbTypes} type 传入缓存类型,默认为session
 * */

export const removeStorageItem = (name: string, type: dbTypes) => {
  return new Promise((resolve, reject) => {
    if (!name) reject('name必须存在')
    if (!type) {
      removeSession(name)
      resolve(true)
      return
    }
    if (name in storeAllState) {
      storeAllState[name].$reset()
    }
    switch (type) {
      case 'session':
        removeSession(name)
        resolve(true)
        break
      case 'local':
        removeStorage(name)
        resolve(true)
        break
      case 'indexedDB':
        try {
          localforage
            .dropInstance({
              name
            })
            .then((res: unknown) => {
              if (res) resolve(JSON.parse(res as string))
            })
        } catch (error) {
          console.error(error)
        }
        break
      default:
        resolve('')
        break
    }
  })
}
/**
 * @function getStoreData 移除传入的store名称对应的缓存数据
 * @param {string} name 传入store名称
 * @param {dbTypes} type 传入缓存类型,默认为session
 * */
export const getStoreItem = (name: string, type: dbTypes) => {
  return new Promise((resolve, reject) => {
    if (!name) reject('name必须存在')
    if (!type) {
      resolve(JSON.parse(getSession(name)) || '')
      return
    }
    let data
    switch (type) {
      case 'session':
        data = JSON.parse(getSession(name)) || ''
        resolve(data)
        break
      case 'local':
        data = JSON.parse(getStorage(name)) || ''
        resolve(data)
        break
      case 'indexedDB':
        localforage
          .createInstance({
            name
          })
          .getItem(name)
          .then((res: unknown) => {
            resolve(JSON.parse(res as string))
          })
        break
      default:
        resolve('')
        break
    }
  })
}

/**
 * @function getStoreData 移除传入的store名称对应的缓存数据
 * @param {string} name 传入store名称
 * @param {dbTypes} type 传入缓存类型,默认为session
 * @param {string} data 传入缓存的数据，必须是字符串
 * */
export const setStoreData = (name: string, type: dbTypes, data: string) => {
  return new Promise((resolve, reject) => {
    if (!name) reject('name必须存在')
    if (!data) reject('data必须存在')
    if (!type) {
      setSession(name, data)
      resolve(true)
      return
    }
    switch (type) {
      case 'session':
        setSession(name, data)
        resolve(true)
        break
      case 'local':
        setStorage(name, data)
        resolve(true)
        break
      case 'indexedDB':
        localforage
          .createInstance({
            name
          })
          .setItem(name, data)
        resolve(true)
        break
      default:
        resolve('')
        break
    }
  })
}

export const piniaStoragePlugin = async (context: PiniaPluginContext): Promise<any> => {
  const { store, options }: any = context
  if (options.persist) {
    if (store && store.$id) {
      const initialState = JSON.parse(JSON.stringify(store.$state))
      let res = await getStoreItem(store.$id, (options!.persist!.type as dbTypes) || 'session')
      if (res) store.$state = res
      store.$reset = () => {
        store.$state = initialState
      }
      storeAllState[store.$id] = store
      store.$subscribe((_mutation: any, state: any) => {
        setStoreData(store.$id, (options!.persist!.type as dbTypes) || 'session', JSON.stringify(state))
      })
    }
  }
}
