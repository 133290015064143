<template>
  <div
    class="w-full h-full px-times-6 py-times-5 flex gap-times-4 box-border modal-tip rounded-times-1"
    v-if="visible">
    <div>
      <iconfont name="icon-icon_warning"></iconfont>
    </div>
    <div class="flex-1">
      <slot></slot>
    </div>
    <div
      class="cursor-pointer px-times-1 hover:bg-bg-normal rounded-[50per]"
      @click=";(visible = !visible), emits('change', visible)">
      <iconfont name="icon-icon_close"></iconfont>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'primary'
  }
})

const visible = ref(true)

const emits = defineEmits(['change'])

const colorData: any = {
  primary: {
    textColor: '#1868FB',
    bgColor: '#E8F4FF'
  },
  warning: {
    textColor: '#FF7D00',
    bgColor: '#FFF7E8'
  },
  error: {
    textColor: '#FF7D00',
    bgColor: '#FFF7E8'
  }
}
</script>
<style lang="less">
.modal-tip {
  color: v-bind('colorData[props.type]?.textColor');
  background: v-bind('colorData[props.type]?.bgColor');
}
</style>
