<template>
  <a-drawer
    :width="width"
    v-bind="finalValue"
    :class="props.isBorder ? 'define-drawer define-drawer-border' : 'define-drawer'">
    <template
      v-for="(_item, key, index) in slots"
      :key="index"
      v-slot:[key]="slotScope">
      <slot
        :name="key"
        v-bind="slotScope"></slot>
    </template>
  </a-drawer>
</template>

<script lang="ts" setup>
import { ref, useAttrs, useSlots, onBeforeUnmount, PropType, watch, computed } from 'vue'

const attrs = useAttrs()
const slots = useSlots()
const defaultSize = {
  sm: 420,
  md: 600,
  lg: 940,
  xl: 1200,
  fullscreen: '100%'
}
const props: { size: keyof typeof defaultSize; isBorder: boolean } = defineProps({
  size: {
    //弹出框尺寸
    type: String as PropType<keyof typeof defaultSize>,
    default: 'sm'
  },
  isBorder: {
    //是否显示边框
    type: Boolean,
    default: false
  }
})

const finalValue = computed(() => {
  const { size, isBorder, width, ...restAttrs } = attrs
  return restAttrs
})

const width = ref(defaultSize[props.size])
</script>

<style lang="less">
.define-drawer {
  .arco-drawer {
    &-header {
      padding: var(--times-7) var(--times-12);
      box-sizing: border-box;
      border-bottom: none;
    }
    &-footer {
      padding: var(--times-6) var(--times-12);
      box-sizing: border-box;
      border-top: none;
    }
  }
}
.define-drawer.define-drawer-border {
  .arco-drawer {
    &-header {
      padding: var(--times-7) var(--times-12);
      box-sizing: border-box;
      border-bottom: 1px solid var(--color-neutral-3);
    }
    &-footer {
      padding: var(--times-6) var(--times-12);
      box-sizing: border-box;
      border-top: 1px solid var(--color-neutral-3);
    }
  }
}
</style>
