/**
 * @function 导出blob格式的方法
 *  @params
 *    data： BlobPart   ->   二进制流
 *    fileName： string   ->  文件名
 *
 */
export const exportFile = (data: BlobPart, fileName?: string) => {
  if (!fileName) {
    fileName = '导出文件.xlsx'
  }
  const url = window.URL.createObjectURL(new Blob([data]))
  const aTags: HTMLAnchorElement = document.createElement('a')
  aTags.style.display = 'none'
  aTags.href = url
  aTags.setAttribute('download', fileName)
  document.body.appendChild(aTags)
  aTags.click()
  document.body.removeChild(aTags)
}

export const ExistFilter = (obj: any) => {
  const returnObj: any = {}
  for (const key in obj) {
    if (!obj[key]) {
      delete obj[key]
    } else {
      const str = encodeURIComponent(key)
      returnObj[str] = obj[key]
    }
  }
  return returnObj
}

/**
 * 参数处理
 * @param {*} params  参数
 */

export function tansParams(params: { [x: string]: any }) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    const part = encodeURIComponent(propName) + '='
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== '' && typeof value[key] !== 'undefined') {
            const params = propName + '[' + key + ']'
            const subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    }
  }
  return result
}

export function getQueryString(locationStr?: string): { origin: string; path: string; query?: { [x: string]: string } } {
  if (!locationStr) locationStr = window.location.href
  const data: any = {}
  const queryString = locationStr.split('?')
  try {
    if (queryString.length > 1) {
      data.path = queryString[0].includes('#') ? queryString[0].split('#')[1] : queryString[0]
      const params = queryString[1].split('&')
      if (params.length) {
        data.query = {}
        for (let i = 0; i < params.length; i++) {
          const paramsArr = params[i].split('=')
          data.query[paramsArr[0]] = paramsArr[1]
        }
      }
    } else {
      data.path = queryString[0].includes('#') ? queryString[0].split('#')[1] : queryString[0]
    }
  } catch (error) {
    console.error(error)
  }
  return data
}

export const generateRandomLetter = (length: number): string => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length)
    result += charset[randomIndex]
  }

  return result
}
