import { removeToken } from '@/utils/auth'
import NProgress from 'nprogress'
import { getToken } from '@/utils/auth'
import { userInfoStore } from '@/store/userInfo'
import { permissionStore } from '@/store/permission'
import router from '@/router'
import { authPermi, authRole } from '@/utils/hasAuth'
import { RouteLocationNormalized, NavigationGuardNext, RouteRecordRaw } from 'vue-router'
import { ResponseData } from '@/interface/userInfo'
import { redirectName } from '@/static'
import { appConfigStore } from '@/store/appconfig'
import registerStore from '@/store/register'
import staticRoutes from './static'

NProgress.configure({ showSpinner: false })

const loginPwd = import.meta.env.VITE_APP_ACCOUNT_LOGIN_PAGE

export const whiteList = ['/', loginPwd, '/auth-redirect', '/401', '/404', '/register']
/**
 * ！注意：目前路由拦截未使用qiweiWhiteList和ssoWhiteList进行路由拦截
 * */
export const qiweiWhiteList = ['/login', '/qiwei-login', loginPwd] //默认企业微信打开的权限白名单
export const ssoWhiteList = ['/login', loginPwd] //默认itb打开的权限白名单

export const removeRoutes = ({ result, origin }: any, to: RouteLocationNormalized, next: NavigationGuardNext) => {
  const originRoutes = origin.map((el: { name: string }) => el.name).join('')
  const allRoutes = router.getRoutes()
  let isContainArr = Array.from({ length: allRoutes.length })

  for (let i = 0; i < allRoutes.length; i++) {
    const el = allRoutes[i]
    if (el.name) {
      isContainArr[i] = to.path.includes(el.name.toString().toLowerCase()) && !originRoutes.includes(el.name)
      if (!isContainArr[i]) {
        const removeRoute = router.addRoute(el)
        removeRoute()
      }
    }
  }

  for (let i = 0; i < staticRoutes.length; i++) {
    router.addRoute(staticRoutes[i] as RouteRecordRaw)
  }
  for (let i = 0; i < result.length; i++) {
    router.addRoute(result[i] as RouteRecordRaw)
  }
  if (isContainArr.includes(true)) {
    next('/index')
    return true
  }
  return false
}

router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    window.document.title = to.meta.title || window._globalConfig?.rootNodeName || import.meta.env.VITE_APP_TITLE
  }
  if (to.meta && to.meta.isBuriedPoint === '0') {
    if (window._paq && window._paq.push) {
      window._paq.push(['setDocumentTitle', window.location.hash])
      window._paq.push(['trackPageView'])
    }
  }
  NProgress.done()
})
// 路由不能使用async请求，会导致next跳转invalid路径
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  // debugger
  NProgress.start()
  // if (!window._globalConfig) {
  //   registerStore().getConfigInfo()
  // }

  // const isQWLogin = window._globalConfig?.ssoType && window._globalConfig?.ssoType != 'itb'
  // const isSSOLogin = window._globalConfig?.ssoType && window._globalConfig?.ssoType = 'itb'

  if (getToken()) {
    if (loginPwd.includes(to.path) || qiweiWhiteList.includes(to.path) || ssoWhiteList.includes(to.path)) {
      next({ path: '/index' })
      NProgress.done()
    } else {
      const userInfo = userInfoStore()
      if (userInfo.userInfo) {
        next()
      } else {
        userInfo
          .getUserInfo()
          .then((info: ResponseData | void) => {
            permissionStore()
              .generateRoutes()
              .then(({ result, origin }: any) => {
                appConfigStore().init()
                if (info?.permissions) {
                  authPermi('*:*:*', info.permissions)
                }
                if (info?.roles) {
                  authRole('admin', info.roles)
                }
                let bool = removeRoutes({ result, origin }, to, next)
                if (!bool) {
                  next({ ...to, replace: true })
                }

                NProgress.done()
              })
              .catch((err) => {
                console.error(err, '获取路由信息报错')
                removeToken()
                next({ path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE })
              })
          })
          .catch((err) => {
            console.error(err, '获取用户信息报错')
            removeToken()
            next({ path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE })
          })
      }
    }
  } else {
    // console.log('aaaaaaaaaaaa')
    // console.log(whiteList, 'whiteList')
    // console.log(qiweiWhiteList, 'whiteList')
    // console.log(ssoWhiteList, 'whiteList')
    // console.log(to.path, 'to.path')
    /**
     * 如果有重定向的页面就重定向进去
     * */

    if (whiteList.includes(to.path) || qiweiWhiteList.includes(to.path) || ssoWhiteList.includes(to.path)) {
      if (to.query && whiteList.includes(to.query[redirectName] as any) && Object.keys(to.query).length > 0) {
        const query: any = {}
        for (const key in to.query) {
          if (key != redirectName) {
            query[key] = to.query[key]
          }
        }
        console.error(1)
        next({ path: to.query[redirectName] ? to.query[redirectName] : loginPwd, query })
      } else {
        console.error(2)
        next()
      }
    } else {
      // next({ path: window._globalConfig?.ssoType == 'qwWeixin' ? '/qiwei-login' : window._globalConfig?.ssoType == 'itb' ? '/login' : import.meta.env.VITE_APP_ACCOUNT_LOGIN_PAGE })
      next({ path: window.VITE_APP_LOGIN_PAGE || import.meta.env.VITE_APP_LOGIN_PAGE })
    }

    NProgress.done()
  }
})
