import VueMatomo from 'vue-matomo'

export default (app: any, router: any) => {
  app.use(VueMatomo, {
    host: window.VITE_APP_MATOMO_ADD || import.meta.env.VITE_APP_MATOMO_ADD,
    siteId: window.VITE_APP_MATOMO_SID || import.meta.env.VITE_APP_MATOMO_SID,
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: false,
    trackerFileName: 'matomo',
    debug: false,
    preInitActions: [
      ['setCustomVariable', 2, 'i通宝-内地网点监控', '監控页面', 'page']
      // ["setCustomVariable", 5, "", "", "page"],
      // ["setCustomVariable", 2, "Branch", "0", "visit"],
    ]
  })
}
