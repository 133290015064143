<template>
  <div class="ip-inputs w-full flex items-center">
    <a-input-group class="box-border flex justify-center items-center border-[1px] border-solid border-border-normal rounded-[2px]">
      <div class="grid grid-cols-4">
        <div
          class="flex items-center"
          v-for="index in 4">
          <a-input
            :key="index - 1"
            ref="inputRef"
            class="h-[32px] outline-0 border-0 text-center"
            :style="{ maxWidth: props.inputWidth + 'px' }"
            @input="handleInput($event, index - 1)"
            @paste="handlePaste($event)"
            @blur="handleBlur"
            @keyup="handleKeyDown($event, index - 1)"
            maxlength="3"
            v-model:model-value="ipSegments[index - 1]" />
          <div
            v-if="index <= 3"
            class="w-[3px] h-[3px] rounded-full bg-word-subtitle"></div>
        </div>
      </div>
    </a-input-group>
    <div v-if="isBatch">&nbsp;~&nbsp;</div>
    <a-input
      v-if="isBatch"
      v-model:model-value="endValue"
      @input="endIpHandleInput"
      class="w-[60px] h-[32px]"></a-input>
  </div>
</template>

<script lang="ts" setup>
import { onUnmounted } from 'vue'
import { watch } from 'vue'
import { ref } from 'vue'

const props = defineProps({
  modelValue: {
    type: String
  },
  inputWidth: {
    type: Number,
    default: 80
  },
  isBatch: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['input', 'blur', 'update:modelValue', 'update:endValue'])
const inputRef = ref()
const ipSegments = ref<string[]>(['', '', '', ''])
const modelValue = ref<string | undefined>()
const endValue = ref()
const clipboardData = ref()
const handleBlur = () => {
  emits('blur')
}

const handleKeyDown = (event: any, index: number) => {
  console.log(event, 'event')
  if (event.keyCode == 8 || event.which == 8 || event.keyCode == 37 || event.which == 37) {
    if (index > 0 && !ipSegments.value[index]) {
      inputRef.value[index - 1].focus()
    }
  } else if (
    event.keyCode == 190 ||
    event.which == 190 ||
    event.keyCode == 110 ||
    event.which == 110 ||
    event.keyCode == 39 ||
    event.which == 39 ||
    event.keyCode == 13 ||
    event.which == 13 ||
    ipSegments.value[index].length >= 3
  ) {
    if (index < 3 && ipSegments.value[index]) {
      inputRef.value[index + 1].focus()
    }
  }
}

const handlePaste = (e: any) => {
  clipboardData.value = e.clipboardData.getData('Text')
}

const handleInput = (e: string, index: number) => {
  console.log(222222)
  if (clipboardData.value) {
    ipSegments.value = clipboardData.value.split('.')
    clipboardData.value = undefined
    modelValue.value = ipSegments.value.join('.')
    emits('input', modelValue.value)
    emits('update:modelValue', modelValue.value)
    emits('update:endValue', endValue.value)

    return
  }

  let str = e.replace(/[^0-9]/g, '')
  ipSegments.value[index] = str
  modelValue.value = ipSegments.value.join('.')
  emits('input', modelValue.value)
  emits('update:modelValue', modelValue.value)
  emits('update:endValue', endValue.value)
}

const endIpHandleInput = () => {
  endValue.value = endValue.value.replace(/[^\d]/g, '')
  if (Number(endValue.value) > 255) {
    endValue.value = 255
  }

  emits('input', modelValue.value)
  emits('update:modelValue', modelValue.value)
  emits('update:endValue', endValue.value)
}

const resetFields = () => {
  ipSegments.value = ['', '', '', '']
}

const initPage = () => {
  if (modelValue.value) {
    ipSegments.value = modelValue.value.split('.')
  } else {
    ipSegments.value = ['', '', '', '']
  }
}

const colseWathc = watch(
  () => props.modelValue,
  () => {
    modelValue.value = props.modelValue
    initPage()
  },
  { immediate: true }
)

onUnmounted(() => {
  colseWathc()
})
defineExpose({
  resetFields
})
</script>
<style>
.ip-inputs .arco-input-wrapper {
  border: 0 !important;
}
</style>
