<template>
  <a-form-item
    v-bind="finalValue"
    title-align="start"
    asterisk-position="end"
    :class="props.titleSign ? 'title-sign' : ''">
    <template
      v-for="(_item, key, index) in slots"
      :key="index"
      v-slot:[key]="slotScope">
      <slot
        :name="key"
        v-bind="slotScope"></slot>
    </template>
  </a-form-item>
</template>

<script lang="ts" setup>
import { computed, useSlots, useAttrs } from 'vue'

const slots: any = useSlots()
const attrs: any = useAttrs()
const props = defineProps({
  titleSign: {
    type: Boolean,
    default: false
  }
})

const finalValue = computed(() => {
  const { titleSign, ...restAttrs } = attrs
  return restAttrs
})
</script>

<style lang="less">
.title-sign {
  .arco-form-item-label {
    display: flex;
    align-items: center;
  }
  .arco-form-item-label::before {
    width: 4px;
    height: 16px;
    margin-right: var(--times-4);
    background-color: rgb(var(--primary-6));
    display: inline-block;
    content: '';
  }
}
</style>
