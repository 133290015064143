import Cookies from 'js-cookie'

const token = 'Admin-token'

export const getToken = () => {
  try {
    return Cookies.get(token)
  } catch (error) {
    console.log(error)
  }
}

export const setToken = (str: string) => {
  try {
    return Cookies.set(token, str)
  } catch (error) {
    console.log(error)
  }
}

export const removeToken = () => {
  try {
    return Cookies.remove(token)
  } catch (error) {
    console.log(error)
  }
}

export const getCookie = (key: string) => {
  try {
    return Cookies.get(key)
  } catch (error) {
    console.log(error)
  }
}

export const setCookie = (key: string, val: any) => {
  try {
    return Cookies.set(key, val)
  } catch (error) {
    console.log(error)
  }
}

export const removeCookie = (key: string) => {
  try {
    return Cookies.remove(key)
  } catch (error) {
    console.log(error)
  }
}

export const getStorage = (key: string): any => {
  try {
    return localStorage.getItem(key)
  } catch (error) {
    console.log(error)
  }
}

export const setStorage = (key: string, val: any) => {
  try {
    return localStorage.setItem(key, val)
  } catch (error) {
    console.log(error)
  }
}

export const removeStorage = (key: string) => {
  try {
    return localStorage.removeItem(key)
  } catch (error) {
    console.log(error)
  }
}

export const getSession = (key: string): any => {
  try {
    return sessionStorage.getItem(key)
  } catch (error) {
    console.log(error)
  }
}

export const setSession = (key: string, val: any) => {
  try {
    return sessionStorage.setItem(key, val)
  } catch (error) {
    console.log(error)
  }
}

export const removeSession = (key: string) => {
  try {
    return sessionStorage.removeItem(key)
  } catch (error) {
    console.log(error)
  }
}
